import React, { useEffect } from "react";
import styled from "styled-components";
import Box from "components/Content/Box";
import InputNew from "components/Ui/InputNew";
import Toggle from "components/Ui/Toggle";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Tooltip from "components/Ui/Tooltip";
import DiscountProducts from "../../DiscountProducts";
import CategoriesInput from "../../CategoriesInput";
import ProductOptions from "./TypeOptions/ProductOptions";
import BogoOptions from "./TypeOptions/BogoOptions";
import CategoryOptions from "./TypeOptions/CategoryOptions";
import FixedPriceOptions from "./TypeOptions/FixedPriceOptions";
import FilterOptions from "./TypeOptions/FilterOptions";
import SelectionOptions from "./TypeOptions/SelectionOptions";
import GWPThresholdOptions from "./TypeOptions/GWPThresholdOptions";

const Container = styled.div`
  margin: 2rem 1rem 1rem 1rem;
  width: 100%;
`;

const AdditionalInput = styled(InputNew)`
  width: calc(100% - 1rem);
`;

const AdditionalToggleWrapper = styled.span`
  width: calc(100% - 1rem);

  p {
    font-size: 1rem;
  }
`;

const AdditionalToggle = styled(Toggle)`
  margin-top: 0.5rem;
  height: 3.5rem;
  width: 6rem;
  border-radius: 10rem;

  div {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
  }
`;

const Row = styled.div`
  width: ${(p) => (p.singleItem ? "50%" : "100%")};
  display: flex;
  flex-direction: column;

  ${MEDIA_MIN_LARGE} {
    flex-direction: row;
  }
`;

const OptionsBox = styled(Box)`
  margin-top: 1rem;
`;

const BogoBox = styled(OptionsBox)`
  margin-bottom: 15rem;
`;

const AdditionalOptions = ({
  register,
  setMinItems,
  setMaxItems,
  setValue,
  errors,
  minItems,
  maxItems,
  combine,
  setCombine,
  discountToCreate,
  updateDiscountToCreate,
  selectedProducts,
  setSelectedProducts,
  excludedProducts,
  setExcludedProducts,
  excludedCategories,
  setExcludedCategories,
  selectedCategories,
  setSelectedCategories,
  availableCurrencies,
  gifts,
  setGifts,
  currencyOptions,
  setCurrencyOptions,
  history,
  filterRules,
  setFilterRules,
  discountRules,
  setDiscountRules,
  control,
  recurrent,
  setRecurrent,
  unregister,
}) => {
  useEffect(() => {
    discountToCreate.type === "FIXED_PRICE" && unregister("discountInPercentage");
  }, [discountToCreate, unregister]);

  const discountInPercentageInput = (
    <AdditionalInput
      {...(discountToCreate.type !== "FIXED_PRICE" &&
        discountToCreate.type !== "GWP_THRESHOLD" &&
        discountToCreate.type !== "GWP_ITEMS" && {
          ...register("discountInPercentage", {
            required: "This is a required field",
            min: {
              value: 0,
              message: "Needs to be a number between 0-100",
            },
            max: {
              value: 100,
              message: "Needs to be a number between 0-100",
            },
          }),
        })}
      onChange={(e) => {
        updateDiscountToCreate({
          discountInPercentage: e.target.value,
        });
        setValue("discountInPercentage", e.target.value);
      }}
      min="0"
      type="number"
      placeholder="0 - 100"
      errors={errors}
      label="Discount in percentage *"
      icon="badge-percent"
    />
  );

  const minItemsInput = (
    <AdditionalInput
      {...register("minItems", {
        min: {
          value: 0,
          message: "Needs to be a positive number",
        },
      })}
      onChange={(e) => {
        setMinItems(e.target.value);
        setValue("minItems", e.target.value);
      }}
      label="Minimum items"
      icon="cart-minus"
      errors={errors}
      type="number"
      placeholder="1"
      value={minItems || ""}
      data-tip="The minimum number of matching items in the cart required for the discount to apply"
    />
  );
  const maxItemsInput = (
    <AdditionalInput
      {...register("maxItems", {
        min: {
          value: 1,
          message: "Needs to be a positive number",
        },
      })}
      onChange={(e) => {
        setMaxItems(e.target.value);
        setValue("maxItems", e.target.value);
      }}
      label="Maximum items"
      icon="cart-plus"
      errors={errors}
      type="number"
      placeholder="1"
      value={maxItems || ""}
      data-tip="The maximum number of matching items that can be discounted in the cart"
    />
  );

  const combineToggle = (
    <AdditionalToggleWrapper>
      <AdditionalToggle
        label="Combine with item discounts"
        id="combine"
        handleToggle={() => setCombine(!combine)}
        data-tip="If this option is enabled, this discount code will stack with discounts on individual items"
        active={combine}
      />
    </AdditionalToggleWrapper>
  );

  const recurrentToggle = (
    <AdditionalToggleWrapper>
      <AdditionalToggle
        label="Recurrent"
        id="recurrent"
        handleToggle={() => setRecurrent(!recurrent)}
        data-tip="If this option is enabled, the discount could be used multiple times within the same cart"
        active={recurrent}
      />
    </AdditionalToggleWrapper>
  );

  const getCategoriesInput = (exclude) => {
    return (
      <CategoriesInput
        selected={exclude ? excludedCategories : selectedCategories}
        setSelected={exclude ? setExcludedCategories : setSelectedCategories}
        exclude={exclude}
      />
    );
  };

  return (
    <>
      {discountToCreate?.type === "PRODUCT" && (
        <>
          <OptionsBox hideHeader>
            <Container>
              <Tooltip />
              <ProductOptions
                Row={Row}
                discountInPercentageInput={discountInPercentageInput}
                minItemsInput={minItemsInput}
                combineToggle={combineToggle}
              />
            </Container>
          </OptionsBox>
          <DiscountProducts
            padding="1rem 0 0 0"
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        </>
      )}
      {discountToCreate?.type === "BOGO" && (
        <BogoBox hideHeader>
          <Container>
            <Tooltip />
            <BogoOptions
              Row={Row}
              discountInPercentageInput={discountInPercentageInput}
              categoriesInput={getCategoriesInput(true)}
              combineToggle={combineToggle}
            />
          </Container>
        </BogoBox>
      )}
      {discountToCreate?.type === "CATEGORY" && (
        <>
          <OptionsBox hideHeader>
            <Container>
              <Tooltip />
              <CategoryOptions
                Row={Row}
                discountInPercentageInput={discountInPercentageInput}
                categoriesInput={getCategoriesInput()}
                combineToggle={combineToggle}
                minItemsInput={minItemsInput}
              />
            </Container>
          </OptionsBox>
          <DiscountProducts
            padding="1rem 0 0 0"
            selectedProducts={excludedProducts}
            selectedCategories={selectedCategories}
            setSelectedProducts={setExcludedProducts}
            exclude={true}
            labelOverrides={{
              heading: "Exclude products",
              subHeading: "Select products that are excluded from the discount",
            }}
          />
        </>
      )}
      {discountToCreate?.type === "FIXED_PRICE" && (
        <OptionsBox
          preHeading="Fixed Price"
          heading="Add discount amount in fixed price"
          subHeading="Discount amount and minimum order value"
          headingIcon="money-bill-alt">
          <Container>
            <Tooltip />
            <FixedPriceOptions
              availableCurrencies={availableCurrencies}
              register={register}
              setValue={setValue}
              discountToCreate={discountToCreate}
              updateDiscountToCreate={updateDiscountToCreate}
              errors={errors}
            />
          </Container>
        </OptionsBox>
      )}
      {discountToCreate?.type === "FILTER" && (
        <FilterOptions
          OptionsBox={OptionsBox}
          Row={Row}
          history={history}
          filterRules={filterRules}
          minItemsInput={minItemsInput}
          control={control}
          discountInPercentageInput={discountInPercentageInput}
          setFilterRules={setFilterRules}
          combineToggle={combineToggle}
        />
      )}
      {discountToCreate?.type === "SELECTION" && (
        <SelectionOptions
          OptionsBox={OptionsBox}
          Row={Row}
          history={history}
          filterRules={filterRules}
          discountRules={discountRules}
          control={control}
          minItems={minItems}
          minItemsInput={minItemsInput}
          maxItemsInput={maxItemsInput}
          discountInPercentageInput={discountInPercentageInput}
          setFilterRules={setFilterRules}
          setDiscountRules={setDiscountRules}
          recurrentToggle={recurrentToggle}
        />
      )}
      {discountToCreate?.type === "GWP_THRESHOLD" && (
        <>
          <GWPThresholdOptions
            OptionsBox={OptionsBox}
            currencyOptions={currencyOptions}
            setCurrencyOptions={setCurrencyOptions}
          />
          <DiscountProducts
            padding="1rem 0 0 0"
            selectVariants={true}
            selectedProducts={gifts}
            setSelectedProducts={setGifts}
            labelOverrides={{
              preHeading: "Discount code",
              heading: "Add gift products",
              subHeading: "",
            }}
          />
        </>
      )}
      {discountToCreate?.type === "GWP_ITEMS" && (
        <>
          <OptionsBox hideHeader={true}>
            {minItemsInput}
          </OptionsBox>

          <DiscountProducts
            padding="1rem 0 0 0"
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            labelOverrides={{
              preHeading: "Discount code",
              heading: "Products in cart",
              subHeading: "",
            }}
          />
          <DiscountProducts
            padding="1rem 0 0 0"
            selectVariants={true}
            selectedProducts={gifts}
            setSelectedProducts={setGifts}
            labelOverrides={{
              preHeading: "Discount code",
              heading: "Add gift products",
              subHeading: "",
            }}
          />
        </>
      )}
    </>
  );
};

export default AdditionalOptions;

import React, { useState } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import Button from "components/Ui/Button";
import TableHeader from "components/Table/Header";
import Inventories from "components/Table/Rows";
import Row from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import TotalItems from "components/Table/Toolbar/TotalItems";
import Loader from "components/Ui/Loader";

const Header = styled(TableHeader)`
  justify-content: flex-start;
`;

const InventoryToolbar = styled(Toolbar)`
  justify-content: flex-end;
`;

const Inventory = styled(Row)`
  width: 100% !important;
  min-height: 6rem;
`;

const Id = styled(Column)`
  width: 25%;
  i {
    margin-right: 2rem;
    font-size: 2rem;
  }
`;

const Name = styled(Column)`
  width: 35%;
`;

const Date = styled(Column)`
  width: 20%;
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};
`;

export default ({ history, inventories, inventoriesTotalHits, loading }) => {
  const [currentSort, setCurrentSort] = useState("down");
  const [sortField, setSortField] = useState("updated");

  const handleClick = (inventory) => {
    history.push({
      pathname: `/admin/inventory/${inventory.id}`,
      state: inventory,
    });
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";
    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField]) || sortField === "active") {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  return (
    <>
      <InventoryToolbar>
        <TotalItems>
          Total inventories: <span>{inventoriesTotalHits || "-"}</span>
        </TotalItems>
      </InventoryToolbar>
      <Header>
        <Id onClick={() => onSortChange("id")}>
          <span>Id </span>
          <SortButton>
            <i className={`fas fa-${getSortType("id", currentSort)}`} />
          </SortButton>
        </Id>
        <Name onClick={() => onSortChange("name")}>
          <span>Name</span>
          <SortButton>
            <i className={`fas fa-${getSortType("name", currentSort)}`} />
          </SortButton>
        </Name>
        <Date onClick={() => onSortChange("updated")}>
          <span>Last updated </span>
          <SortButton>
            <i className={`fas fa-${getSortType("updated", currentSort)}`} />
          </SortButton>
        </Date>
        <Date onClick={() => onSortChange("created")}>
          <span>Created</span>
          <SortButton>
            <i className={`fas fa-${getSortType("created", currentSort)}`} />
          </SortButton>
        </Date>
      </Header>
      <Inventories>
        {loading && <Loader />}
        {inventories?.length > 0 && (
          <>
            {[...inventories].sort(sortTypes[currentSort].fn).map((inventory) => {
              return (
                <Inventory key={uuid()} onClick={() => handleClick(inventory)}>
                  <Id>
                    <i className="fa-duotone fa-warehouse-full"></i>
                    <strong>{inventory.id}</strong>
                  </Id>
                  <Name>{inventory.name}</Name>
                  <Date>
                    <Moment format="YYYY-MM-DD HH:mm">{inventory.updated}</Moment>
                  </Date>
                  <Date>
                    <Moment format="YYYY-MM-DD HH:mm">{inventory.created}</Moment>
                  </Date>
                </Inventory>
              );
            })}
          </>
        )}
      </Inventories>
    </>
  );
};

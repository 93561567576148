import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Box from "components/Dashboard/Box";
import styled from "styled-components/macro";
import ChartistGraph from "react-chartist";
import ORDER_AGGREGATE from "graphql/Order/OrderAggregate";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import moment from "moment-timezone";
import { mergeDineros } from "../../../helpers/mergeDineros";
import calculateSales from "../../../helpers/sales";
import { Currency } from "../../../helpers/money";
import getRevenueQuery from "../../../helpers/getRevenueQuery";
import esb from "elastic-builder";
import { isEmpty } from "lodash";

const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

export default ({ currencyUnit, selectedStores, rates, includeVAT }) => {
  if (!currencyUnit || !selectedStores || isEmpty(rates)) return <Loader />;

  const yesterday = moment().subtract(1, "days").startOf("day");
  const today = moment().endOf("day");
  const Amount = Currency(currencyUnit);

  const dailyRevenueQuery = new esb.requestBodySearch()
    .query(
      esb.boolQuery().must([
        esb.matchQuery("statusLog.status", "success"),
        esb.termsQuery(
          "store.keyword",
          selectedStores.map((s) => s.value)
        ),
        esb
          .rangeQuery("created")
          .gte(yesterday.format("YYYY-MM-DD"))
          .format("yyyy-MM-dd")
          .timeZone(moment.tz.guess()),
      ])
    )
    .agg(
      esb
        .termsAggregation("stores", "store.keyword")
        .size(300)
        .agg(
          esb
            .dateHistogramAggregation("created", "created", "1d")
            .agg(
              esb
                .sumAggregation("sales")
                .script(esb.script().lang("painless").inline(getRevenueQuery(includeVAT)))
            )
        )
    );

  const [dailySalesChart, setDailySalesChart] = useState();
  const [prevPeriodSum, setPrevPeriodSum] = useState();
  const [currentPeriodSum, setCurrentPeriodSum] = useState();

  const { loading, error, data } = useQuery(ORDER_AGGREGATE, {
    variables: { query: JSON.stringify(dailyRevenueQuery.toJSON()) },
  });

  const toDataItem = async (stats, selectedStores, date, currencyUnit) => {
    const totalSales = [];
    const storeBuckets = stats.stores.buckets;
    for (const store of selectedStores) {
      let sale = {
        sales: 0,
        currencyUnit: store.currencyUnit,
      };
      const storeBucket = storeBuckets.find((c) => c.key === store.value);
      if (storeBucket) {
        for (const sales of storeBucket.created.buckets) {
          if (moment(sales.key_as_string).format("YYYY-MM-DD") === date) {
            sale = {
              sales: calculateSales(sales.sales.value, store.currencyUnit, store.tax, includeVAT),
              currencyUnit: store.currencyUnit,
            };
          }
        }
      }
      totalSales.push(sale);
    }

    return {
      key: date,
      value: await mergeDineros(
        totalSales.map((s) => {
          const Amount = Currency(s.currencyUnit);
          return Amount(s.sales);
        }),
        currencyUnit,
        rates
      ),
    };
  };

  useEffect(() => {
    const handleData = async () => {
      const aggregations = JSON.parse(data.orderAggregates.aggregations);

      const [currentDiagramData, prevDiagramData] = await Promise.all([
        toDataItem(aggregations, selectedStores, today.format("YYYY-MM-DD"), currencyUnit),
        toDataItem(aggregations, selectedStores, yesterday.format("YYYY-MM-DD"), currencyUnit),
      ]);

      const prevPeriodAmount = prevDiagramData.value.toUnit();
      const currentPeriodAmount = currentDiagramData.value.toUnit();
      setPrevPeriodSum(prevDiagramData.value);
      setCurrentPeriodSum(currentDiagramData.value);

      const dailySalesChart = {
        data: {
          series: [currentPeriodAmount, prevPeriodAmount],
        },
        options: {
          donut: true,
          donutWidth: 15,
          donutSolid: true,
          startAngle: 0,
          showLabel: false,
        },
      };
      setDailySalesChart(dailySalesChart);
    };

    data && handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currencyUnit, includeVAT]);

  if (loading || !currentPeriodSum || !prevPeriodSum) return <Loader />;
  if (error)
    return <ErrorMessage>An error occurred when loading data, please contact support</ErrorMessage>;

  return (
    <Box
      preHeading="Daily revenue"
      heading={currentPeriodSum ? currentPeriodSum.toFormat() : ""}
      headingIcon="money-bill-alt"
      primaryLabel="Today"
      secondaryLabel="Yesterday"
      currentPeriodSum={currentPeriodSum ? currentPeriodSum : Amount(0)}
      prevPeriodSum={prevPeriodSum ? prevPeriodSum : Amount(0)}
      currency={currencyUnit}
      displayAsCurrency>
      {dailySalesChart && (
        <Container>
          <ChartistGraph
            className="ct-chart-white-colors"
            data={dailySalesChart.data}
            type="Pie"
            options={dailySalesChart.options}
          />
        </Container>
      )}
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import uuid from "react-uuid";
import styled from "styled-components/macro";
import { useAdminContext } from "context/AdminContext";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Pagination from "components/Pagination/Pagination";
import Money from "components/Money/Money";
import Button from "components/Ui/Button";
import Tooltip from "components/Ui/Tooltip";
import FlagIcon from "components/Ui/FlagIcon";
import HoM from "components/Ui/HoM";
import OrderStatus from "components/Order/OrderStatus";
import TableHeader from "components/Table/Header";
import Orders from "components/Table/Rows";
import Order from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import getFriendlyPaymentName from "helpers/getFriendlyPaymentName";
import getFullCountryName from "helpers/getFullCountryName";
import Loader from "components/Ui/Loader";

const Reference = styled(Column)`
  width: 20%;
  padding-left: 1rem;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 10%;
    padding-left: 1rem;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const Status = styled(Column)`
  width: 12%;
  font-size: 1.3rem;
  i {
    margin-right: 0;
  }

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }
`;

const Tax = styled(Column)`
  width: 30%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 15%;
  }
`;

const AmountWithDiscount = styled(Column)`
  width: 33%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 15%;
  }
`;

const PaymentMethod = styled(Column)`
  width: 15%;
  padding-right: 2rem;
  font-size: 1.3rem;
`;

const Country = styled(Column)`
  width: 15%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }
  img {
    margin: 0.5rem 0 0;
    padding-bottom: 0.3rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 0.2rem;
    }
  }
`;

const Date = styled(Column)`
  width: 35%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 12.5%;
  }
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};

  i {
    font-size: 1.6rem;
  }
`;

const MAX_RESULT_WINDOW = 10000;

export default function OrdersTable({
  orders,
  setOrders,
  ordersTotalHits,
  fetchMore,
  updatedOrder,
  esbQuery,
  loading,
  setTabs,
  tabs,
  setActiveTab,
  children: renderTableActions = null,
}) {
  const [goToPageInput, setGoToPageInput] = useState("1");
  const [currentSort, setCurrentSort] = useState("default");
  const [sortField, setSortField] = useState("reference");
  const { currentPageOrder, setCurrentPageOrder, ORDERS_PER_PAGE } = useAdminContext();
  const [itemsPerPage, setItemsPerPage] = useState(ORDERS_PER_PAGE);

  useEffect(() => {
    let isMounted = true;
    const from = (currentPageOrder - 1) * itemsPerPage;
    fetchMore({
      variables: {
        query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()),
      },
    })
      .then((result) => {
        document.querySelector(".top").scrollIntoView({ behavior: "smooth" });
        isMounted && setOrders(result.data.searchOrders.orders);
      })
      .catch((error) => console.log(error));

    updatedOrder &&
      orders &&
      setOrders(
        orders.map((o) => (o.id === updatedOrder.id ? { ...o, status: updatedOrder.status } : o))
      );
    setGoToPageInput(currentPageOrder);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageOrder, itemsPerPage, updatedOrder]);

  const handleOrderClick = (id, reference, status, created) => {
    !tabs.find((item) => item.id === id) &&
      setTabs((prev) => [...prev, { id, reference, status, created }]);
    setActiveTab(id);
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField])) {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const getSortType = (field, sort) => {
    if (field === sortField) {
      return sortTypes[sort].class;
    }
    return sortTypes.default.class;
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const formatName = (order) =>
    order.shippingAddress
      ? `${order.shippingAddress.givenName} ${order.shippingAddress.familyName}`
      : "";

  return (
    <TableWrapper>
      <Tooltip />
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={(e) => setCurrentPageOrder(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of{" "}
            {ordersTotalHits
              ? Math.ceil(Math.min(ordersTotalHits, MAX_RESULT_WINDOW) / itemsPerPage)
              : "-"}{" "}
            pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <HoM>Total </HoM> orders: <span>{ordersTotalHits || "-"}</span>
        </TotalItems>
        {renderTableActions &&
          renderTableActions({ totalHits: Math.min(ordersTotalHits, MAX_RESULT_WINDOW) })}
      </Toolbar>

      <TableHeader>
        <Reference onClick={() => onSortChange("reference")}>
          <span>
            <HoM>Order</HoM> #
          </span>
          <SortButton>
            <i className={`fas fa-${getSortType("reference", currentSort)}`} />
          </SortButton>
        </Reference>
        <Status onClick={() => onSortChange("status")}>
          <HoM>Status</HoM>
          <SortButton>
            <i className={`fas fa-${getSortType("status", currentSort)}`} />
          </SortButton>
        </Status>
        <Tax hideOnMobile onClick={() => onSortChange("orderTaxAmount")}>
          <span>Tax </span>
          <SortButton>
            <i className={`fas fa-${getSortType("orderTaxAmount", currentSort)}`} />
          </SortButton>
        </Tax>
        <AmountWithDiscount onClick={() => onSortChange("orderAmountWithDiscount")}>
          <span>Amount with discount </span>
          <SortButton>
            <i className={`fas fa-${getSortType("orderAmountWithDiscount", currentSort)}`} />
          </SortButton>
        </AmountWithDiscount>
        <PaymentMethod hideOnMobile onClick={() => onSortChange("paymentMethod")}>
          <span>Payment method </span>
          <SortButton>
            <i className={`fas fa-${getSortType("paymentMethod", currentSort)}`} />
          </SortButton>
        </PaymentMethod>
        <Country hideOnMobile onClick={() => onSortChange("country")}>
          <HoM>Country</HoM>
          <SortButton>
            <i className={`fas fa-${getSortType("country", currentSort)}`} />
          </SortButton>
        </Country>
        <Date onClick={() => onSortChange("created")}>
          <span>Created </span>
          <SortButton>
            <i className={`fas fa-${getSortType("created", currentSort)}`} />
          </SortButton>
        </Date>
        <Date hideOnMobile onClick={() => onSortChange("lastUpdated")}>
          <span>Last updated </span>
          <SortButton>
            <i className={`fas fa-${getSortType("lastUpdated", currentSort)}`} />
          </SortButton>
        </Date>
      </TableHeader>
      <Orders>
        {loading && <Loader />}
        {orders && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={Math.min(ordersTotalHits, MAX_RESULT_WINDOW)}
            currentPage={currentPageOrder}
            setCurrentPage={setCurrentPageOrder}>
            {[...orders]
              .map((o) => ({
                ...o,
                country: o.shippingAddress ? o.shippingAddress.country : "",
                paymentMethod: o.paymentMethod || "",
              }))
              .sort(sortTypes[currentSort].fn)
              .map((order) => (
                <Order
                  key={uuid()}
                  onClick={() =>
                    handleOrderClick(order.id, order.reference, order.status, order.created)
                  }>
                  <Reference>
                    <span data-tip={formatName(order)}>
                      <strong>{order.reference}</strong>
                    </span>
                  </Reference>
                  <Status>
                    <OrderStatus status={order.status} showIcon />
                  </Status>
                  <Tax hideOnMobile>
                    <Money amount={order.orderTaxAmount} currencyUnit={order.currencyUnit} />
                  </Tax>
                  <AmountWithDiscount>
                    <Money
                      amount={
                        order.taxIncludedPricing === false
                          ? order.orderAmountWithDiscount + order.orderTaxAmount
                          : order.orderAmountWithDiscount
                      }
                      currencyUnit={order.currencyUnit}
                    />
                  </AmountWithDiscount>
                  <PaymentMethod hideOnMobile>
                    {order.paymentMethod ? getFriendlyPaymentName(order.paymentMethod) : "-"}
                  </PaymentMethod>
                  <Country hideOnMobile>
                    <span data-tip={getFullCountryName(order.country)}>
                      {order.country ? <FlagIcon countryCode={order.country} /> : "-"}
                    </span>
                  </Country>
                  <Date>
                    <Moment format="YYYY-MM-DD, HH:mm">{order.created}</Moment>
                  </Date>
                  <Date hideOnMobile>
                    <Moment format="YYYY-MM-DD, HH:mm">{order.lastUpdated}</Moment>
                  </Date>
                </Order>
              ))}
          </Pagination>
        )}
      </Orders>
    </TableWrapper>
  );
}

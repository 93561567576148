import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Box from "components/Dashboard/Box";
import styled from "styled-components/macro";
import ChartistGraph from "react-chartist";
import moment from "moment-timezone";
import { Currency } from "../../../helpers/money";
import ORDER_AGGREGATE from "graphql/Order/OrderAggregate";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { mergeDineros } from "../../../helpers/mergeDineros";
import calculateSales from "../../../helpers/sales";
import getRevenueQuery from "../../../helpers/getRevenueQuery";
import esb from "elastic-builder";
import { isEmpty } from "lodash";

const Container = styled.div`
  width: 100%;
  margin: 3rem 0 0;
`;

const getDailyRevenueQuery = (
  startDateTime,
  endDateTime,
  interval,
  selectedStores,
  format,
  includeVAT
) =>
  new esb.requestBodySearch()
    .query(
      esb.boolQuery().must([
        esb.matchQuery("statusLog.status", "success"),
        esb.termsQuery(
          "store.keyword",
          selectedStores.map((s) => s.value)
        ),
        esb
          .rangeQuery("created")
          .gte(startDateTime.format(format))
          .lte(endDateTime.format(format))
          .format(format.replace("YYYY", "yyyy").replace("DD", "dd"))
          .timeZone(moment.tz.guess()),
      ])
    )
    .agg(
      esb
        .termsAggregation("stores", "store.keyword")
        .size(300)
        .agg(
          esb
            .dateHistogramAggregation("created", "created", interval)
            .agg(
              esb
                .sumAggregation("sales")
                .script(esb.script().lang("painless").inline(getRevenueQuery(includeVAT)))
            )
        )
    );

export default ({ selectedStores, currencyUnit, period, rates, includeVAT }) => {
  if (!currencyUnit || !selectedStores || isEmpty(rates)) return <Loader />;

  const [dailySalesChart, setDailySalesChart] = useState();
  const [currentPeriodSum, setCurrentPeriodSum] = useState(0);

  const Amount = Currency(currencyUnit);

  const getTimeRange = (startDateTime, endDateTime, type, format) => {
    const from = moment(startDateTime);
    const to = moment(endDateTime).add(1, type);
    return [...Array(to.diff(from, type)).keys()].map((i) =>
      moment(startDateTime).add(i, type).format(format)
    );
  };

  const toDataItem = async (stats, selectedStores, date, currencyUnit, format) => {
    const totalSales = [];
    const storeBuckets = stats.stores.buckets;
    for (const store of selectedStores) {
      let sale = {
        sales: 0,
        currencyUnit: store.currencyUnit,
      };
      const storeBucket = storeBuckets.find((c) => c.key === store.value);
      if (storeBucket) {
        for (const sales of storeBucket.created.buckets) {
          if (moment(sales.key_as_string).format(format) === date) {
            sale = {
              sales: calculateSales(sales.sales.value, store.currencyUnit, store.tax, includeVAT),
              currencyUnit: store.currencyUnit,
            };
          }
        }
      }
      totalSales.push(sale);
    }

    return {
      key: date,
      value: await mergeDineros(
        totalSales.map((s) => {
          const Amount = Currency(s.currencyUnit);
          return Amount(s.sales);
        }),
        currencyUnit,
        rates
      ),
    };
  };

  const toData = (stats, selectedStores, timeRange, currencyUnit, format) =>
    timeRange.map((d) => toDataItem(stats, selectedStores, d, currencyUnit, format));

  const {
    loading: currentLoading,
    error: currentError,
    data: currentStats,
  } = useQuery(ORDER_AGGREGATE, {
    variables: {
      query: JSON.stringify(
        getDailyRevenueQuery(
          period.current.startDate,
          period.current.endDate,
          period.interval,
          selectedStores,
          period.format,
          includeVAT
        ).toJSON()
      ),
    },
  });

  useEffect(() => {
    const handleData = async () => {
      const currentTimeRange = getTimeRange(
        period.current.startDate,
        period.current.endDate,
        period.type,
        period.format
      );

      const currentDiagramData = await Promise.all(
        toData(
          JSON.parse(currentStats.orderAggregates.aggregations),
          selectedStores,
          currentTimeRange,
          currencyUnit,
          period.format
        )
      );

      const currentPeriodAmount = currentDiagramData.map((n) => n.value.toUnit());
      const currentData =
        currentDiagramData.length > 0
          ? currentDiagramData.map((d) => d.value).reduce((a, b) => a.add(b))
          : null;
      setCurrentPeriodSum(currentData);

      const labels =
        period.current.endDate.diff(period.current.startDate, "days") < 40
          ? getTimeRange(
              period.current.startDate,
              period.current.endDate,
              period.type,
              period.shortFormat,
              true
            )
          : null;

      const dailySalesChart = {
        data: {
          labels: labels,
          series: [currentPeriodAmount],
        },
        options: {
          low: 0,
          high: Math.max.apply(Math, currentPeriodAmount),
          showArea: true,
          showPoint: false,
          fullWidth: true,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
            labelInterpolationFnc: function (value) {
              return Amount(value * 100)
                .toFormat("$0,0")
                .replace(",000", "K")
                .replace(",500", ",5K");
            },
          },
        },
      };
      setDailySalesChart(dailySalesChart);
    };
    currentStats && rates && handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStats, selectedStores, currencyUnit, rates, includeVAT]);

  if (currentLoading) return <Loader />;
  if (currentError)
    return <ErrorMessage>An error occurred when loading data, please contact support</ErrorMessage>;

  return (
    <Box
      preHeading="Revenue"
      heading={currentPeriodSum ? currentPeriodSum.toFormat() : ""}
      headingIcon="money-bill-alt"
      currency={currencyUnit}
      currentPeriodSum={currentPeriodSum ? currentPeriodSum : Amount(0)}
      displayAsCurrency
      primaryLabel={`${period.current.startDate.format(
        "YYYY-MM-DD"
      )} - ${period.current.endDate.format("YYYY-MM-DD")}`}
      labelPos="topRight"
      footer={period.label}>
      {dailySalesChart && (
        <Container>
          <ChartistGraph
            className="ct-chart-white-colors ct-major-tenth"
            data={dailySalesChart ? dailySalesChart.data : {}}
            type="Line"
            options={dailySalesChart ? dailySalesChart.options : {}}
            listener={{
              draw: (data) => {
                if (data.type === "line" || data.type === "area") {
                  data.element.animate({
                    d: {
                      begin: 0,
                      dur: 500,
                      from: data.path
                        .clone()
                        .scale(1, 0)
                        .translate(0, data.chartRect.height())
                        .stringify(),
                      to: data.path.clone().stringify(),
                    },
                  });
                }
              },
            }}
          />
        </Container>
      )}
    </Box>
  );
};

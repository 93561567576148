import React from "react";
import { Query } from "@apollo/client/react/components";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import GET_INVENTORIES from "graphql/Stock/GetInventories";
import Header from "components/Header/Header";
import Box from "components/Content/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Store/Sidebar";
import InventoriesTable from "./InventoriesTable";

const Stores = (props) => {
  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/configurations", "Configurations"],
          ["admin/inventories", "Inventories"],
        ]}
      />
      <Header heading="Inventories"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <Box preHeading="Overview" heading="Inventories">
              <Query query={GET_INVENTORIES}>
                {({ error, data, loading }) => {
                  if (error)
                    return (
                      <ErrorMessage>An error occurred getting data, contact support</ErrorMessage>
                    );
                  const inventories = data?.getInventories;
                  return (
                    <InventoriesTable
                      inventoriesTotalHits={inventories?.length}
                      inventories={inventories}
                      history={props.history}
                      loading={loading}
                    />
                  );
                }}
              </Query>
            </Box>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return Stores(props);
};

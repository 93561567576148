import React, { useState } from "react";
import styled from "styled-components";
import PageContainer from "components/Page/PageContainer";
import esb from "elastic-builder";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Sidebar from "components/Configuration/Sidebar";
import Box from "components/Content/Box";
import { Query } from "@apollo/client/react/components";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Rows from "components/Table/Rows";
import Row from "components/Table/Row";
import GET_CONFIGURATIONS from "graphql/Configuration/GetConfigurations";
import SEARCH_SHIPPING_OPTIONS from "graphql/Product/SearchShippingOptions";
import { useHistory } from "react-router-dom";
import ALL_STORES from "graphql/Store/AllStores";
import FlagIcon from "components/Ui/FlagIcon";
import getFullCountryName from "helpers/getFullCountryName";
import SEARCH_SHIPPING_RULES from "graphql/Shipping/ShippingRule/SearchShippingRules";
import Button from "components/Ui/Button";
import Tooltip from "components/Ui/Tooltip";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Icon = styled.i`
  font-size: 2.5rem;
  margin-right: 1.5rem;
  color: ${(p) => p.theme.colors.primary};
`;

const GreyIcon = styled(Icon)`
  color: ${(p) => p.theme.colors.secondary};
`;

const StyledBox = styled(Box)`
  ${MEDIA_MIN_LARGE} {
    min-height: ${(p) => (p.large ? "55rem" : "40rem")};
  }
`;

const StyledRow = styled(Row)`
  text-transform: capitalize;
  min-height: 6rem;
  img {
    width: 4rem;
    margin-right: 1rem;
  }
`;

const FlagTooltip = styled(Tooltip)`
  position: relative;
  top: -1rem;
`;

const Flags = styled.div`
  width: 100%;
  img {
    width: 2.3rem;
    margin: 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }
`;

const EmptyMessage = styled.div`
  ${MEDIA_MIN_LARGE} {
    height: ${(p) => (p.large ? "40rem" : "25rem")};
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
  max-width: 40rem;
`;

const Configurations = () => {
  const [allStores, setAllStores] = useState([]);
  const [totalShippingOptions, setTotalShippingOptions] = useState();
  const [totalShippingRules, setTotalShippingRules] = useState();
  const [shippingOptionsLoaded, setShippingOptionsLoaded] = useState();
  const [shippingRulesLoaded, setShippingRulesLoaded] = useState();
  const [integrationsLoaded, setIntegrationsLoaded] = useState();
  const [webhooksLoaded, setWebhooksLoaded] = useState();
  const [stores, setStores] = useState([]);
  const history = useHistory();

  const ALL_SHIPPING_OPTIONS = new esb.requestBodySearch()
    .sort(esb.sort("lastUpdated", "desc"))
    .query(esb.queryStringQuery("type:shippingOption AND !(archived:1)"))
    .size(5)
    .from(0);

  const ALL_SHIPPING_RULES = new esb.requestBodySearch()
    .query(
      esb.boolQuery().must(esb.matchQuery("type", "SHIPPING")).must(esb.matchQuery("archived", 0))
    )
    .sort(esb.sort("lastUpdated", "desc"))
    .size(5)
    .from(0);

  const integrations = ["fortnox", "klaviyo"];

  return (
    <>
      <Breadcrumbs slugs={[["admin/configurations", "Configurations"]]} />
      <Header heading="Configurations"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="6">
            <StyledBox
              heading={shippingOptionsLoaded && "Shipping Options"}
              subHeading={shippingOptionsLoaded && `Total: ${totalShippingOptions}`}
              large>
              <Query
                query={SEARCH_SHIPPING_OPTIONS}
                variables={{ query: JSON.stringify(ALL_SHIPPING_OPTIONS.toJSON()) }}>
                {({ loading, error, data }) => {
                  if (loading) return <Loader />;
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );
                  if (data) {
                    setTotalShippingOptions(data?.searchProducts?.totalHits);
                    setShippingOptionsLoaded(true);
                    return (
                      <>
                        {totalShippingOptions > 0 ? (
                          <>
                            <Rows>
                              {data?.searchProducts?.products?.map((s) => (
                                <StyledRow
                                  onClick={() => history.push(`/admin/shipping-option/${s.id}`, { id: s.id })}>
                                  <img alt="" src={s.imageUrl}></img>
                                  {s.name}
                                </StyledRow>
                              ))}
                            </Rows>
                            <StyledButton onClick={() => history.push(`/admin/shipping-options`)}>
                              Show all
                            </StyledButton>
                          </>
                        ) : (
                          <EmptyMessage large>No configured shipping options</EmptyMessage>
                        )}
                      </>
                    );
                  }
                }}
              </Query>
            </StyledBox>
          </GridItem>
          <GridItem columns="6">
            <StyledBox
              subHeading={shippingRulesLoaded && `Total: ${totalShippingRules}`}
              heading={shippingRulesLoaded && "Shipping Rules"}
              large>
              <Query
                query={SEARCH_SHIPPING_RULES}
                variables={{ query: JSON.stringify(ALL_SHIPPING_RULES.toJSON()) }}>
                {({ loading, error, data }) => {
                  if (loading) return <Loader />;
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );
                  if (data) {
                    setTotalShippingRules(data.searchRules.totalHits);
                    setShippingRulesLoaded(true);
                    return (
                      <>
                        {totalShippingRules > 0 ? (
                          <>
                            <Rows>
                              {data.searchRules.rules.map((rule) => (
                                <StyledRow
                                  onClick={() => history.push(`/admin/shipping-rule/${rule.id}`)}>
                                  <GreyIcon className="fa-solid fa-memo-circle-info" />
                                  {rule.name}
                                </StyledRow>
                              ))}
                            </Rows>
                            <StyledButton onClick={() => history.push(`/admin/shipping-rules`)}>
                              Show all
                            </StyledButton>
                          </>
                        ) : (
                          <EmptyMessage large>No configured shipping rules</EmptyMessage>
                        )}
                      </>
                    );
                  }
                }}
              </Query>
            </StyledBox>
          </GridItem>
          <GridItem columns="6">
            <StyledBox heading={integrationsLoaded && "Integrations"}>
              <Query query={GET_CONFIGURATIONS}>
                {({ loading, error, data }) => {
                  if (loading) return <Loader />;
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );
                  if (data) {
                    setIntegrationsLoaded(true);
                    return (
                      <Rows>
                        {integrations.map((integration) => (
                          <StyledRow onClick={() => history.push(`/admin/${integration}`)}>
                            {data[integration].length > 0 ? (
                              <Icon className="fa-solid fa-circle-check" />
                            ) : (
                              <GreyIcon className="fa-solid fa-circle-xmark" />
                            )}
                            {integration}
                          </StyledRow>
                        ))}
                      </Rows>
                    );
                  }
                }}
              </Query>
            </StyledBox>
          </GridItem>
          <GridItem columns="6">
            <StyledBox heading={webhooksLoaded && "Webhooks"}>
              <Query query={GET_CONFIGURATIONS}>
                {({ loading, error, data }) => {
                  if (loading) return <Loader />;
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );
                  if (data) {
                    setWebhooksLoaded(true);
                    return (
                      <>
                        {data["brink"].length > 0 ? (
                          <Rows>
                            {data["brink"].map((webhook) => (
                              <StyledRow
                                key={webhook.name}
                                onClick={() =>
                                  history.push(`/admin/order-webhook/${webhook.name}`)
                                }>
                                <Icon className="fa-solid fa-square-code" />
                                {webhook.name}
                              </StyledRow>
                            ))}
                          </Rows>
                        ) : (
                          <EmptyMessage>No configured webhooks</EmptyMessage>
                        )}
                      </>
                    );
                  }
                }}
              </Query>
            </StyledBox>
          </GridItem>
          <Query
            query={ALL_STORES}
            variables={{ from: 0, size: 300 }}
            onCompleted={({ allStores }) => {
              setStores(allStores.stores);
              setAllStores(allStores.stores);
            }}>
            {({ loading, error, data }) => {
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );
              return (
                <GridItem columns="12">
                  <Box subHeading={`Total: ${allStores.length}`} heading="Stores">
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <Flags>
                          {stores.map((store) => (
                            <span data-tip={getFullCountryName(store.countryCode)}>
                              <FlagTooltip />
                              <FlagIcon
                                countryCode={store.countryCode}
                                onClick={() =>
                                  history.push({
                                    pathname: `/admin/store/${store.countryCode}`,
                                    state: store,
                                  })
                                }
                              />
                            </span>
                          ))}
                        </Flags>
                        <StyledButton onClick={() => history.push(`/admin/stores`)}>
                          Show all
                        </StyledButton>
                      </>
                    )}
                  </Box>
                </GridItem>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default Configurations;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { Mutation, Query } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form";
import TypeButtons from "../../../components/Discount/DiscountCode/TypeButtons";
import GeneralOptions from "../../../components/Discount/DiscountCode/Options/GeneralOptions";
import AdditionalOptions from "../../../components/Discount/DiscountCode/Options/AdditionalOptions";
import ADD_DISCOUNT_CODE from "graphql/Discount/DiscountCode/AddDiscountCode";
import SaveBar from "components/Ui/SaveBar";
import GET_DISCOUNT_TYPES from "graphql/Discount/DiscountCode/GetDiscountTypes";
import { useNotification } from "context/NotificationContext";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Discount/Sidebar";
import uuid from "react-uuid";
import Label from "components/AttributeList/Label";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import { formatPrice } from "../../../helpers/money";
import useTabState from "../../../helpers/useTabstate";

const TypeDescription = styled.div`
  border: ${(p) => `${p.theme.colors.primary} solid 0.2rem`};
  width: 100%;
  display: flex;
  border-radius: 0.5rem;
  margin: 0 1rem 5rem 1rem;
  flex-direction: column;

  p {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    padding: 2rem 3rem;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  top: 2rem;
  left: -0.8rem;
  background-color: ${(p) => p.theme.colors.secondaryBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(p) => `${p.theme.colors.secondaryBackground} solid 0.4rem`};
  border-radius: 50%;
`;

const DescriptionIcon = styled.i`
  color: ${(p) => p.theme.colors.primary};
  font-size: 3rem;
  position: relative;
  top: 0.1rem;
  left: 0.05rem;
`;

const TypeLabel = styled(Label)`
  margin-left: 1rem;
`;

export default ({ history }) => {
  const { setNotification } = useNotification();
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    unregister,
    setValue,
  } = useForm();
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [minItems, setMinItems] = useState(1);
  const [maxItems, setMaxItems] = useState(1);
  const [combine, setCombine] = useState(false);
  const [recurrent, setRecurrent] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [gifts, setGifts] = useState({
    products: [],
    expandedProducts: [],
  })
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [tabs, setTabs] = useTabState([], "discountcode-tabs");
  const [discountToCreate, setDiscountToCreate] = useState({
    type: null,
    freeShipping: false,
  });
  const [selectedProducts, setSelectedProducts] = useState({
    products: [],
    expandedProducts: [],
  });
  const [excludedProducts, setExcludedProducts] = useState({
    products: [],
    expandedProducts: [],
  });
  const [excludedCategories, setExcludedCategories] = useState([
    { value: "Shipping", label: "Shipping" },
  ]);
  const [filterRules, setFilterRules] = useState([
    { mode: "INCLUDE", key: "", values: [], id: uuid() },
  ]);
  const [discountRules, setDiscountRules] = useState([
    { mode: "INCLUDE", key: "", values: [], id: uuid() },
  ]);

  const getTypeExplanation = (type) => {
    if (
      discountToCreate?.code &&
      discountToCreate?.startDate &&
      discountToCreate?.endDate &&
      discountToCreate?.stores?.length > 0 &&
      discountToCreate?.type &&
      !isComingSoon(type)
    ) {
      switch (type) {
        case "PRODUCT":
          return "Discount in percentage on selected products";

        case "BOGO":
          return "Buy one product and get a percentage discount on the next one. The discount will be applied on the cheapest product. There is the option to exclude product categories from this discount.";

        case "CATEGORY":
          return "Discount in percentage on products in selected categories. There is the option to exclude specific products from this discount.";

        case "FIXED_PRICE":
          return "Discount in a fixed amount on the entire cart. The discount can’t go lower than 0. There is the option to select a minimum amount on the cart for the discount to be valid.";

        case "FILTER":
          return "Discount in percentage on products that matches a set of rules. The filter applies a discount in percentage on specific brands, colors, sizes etc.";

        case "SELECTION":
          return "By adding X certain items to the cart the shopper can then select up to Y other items that receive a percentage discount.";

        case "GWP_THRESHOLD":
          return "Gift with purchase when cart has value above threshold.";

        case "GWP_ITEMS":
          return "Gift with purchase when selected items are in the cart.";

        default:
          return type;
      }
    } else if (isComingSoon(type)) {
      return "Coming soon";
    } else {
      return "Input all required information to continue";
    }
  };

  const isComingSoon = (type) => type === "";

  const additionalOptionsRef = useRef(null);

  const updateSelectedStores = (stores) => {
    updateDiscountToCreate({
      stores: stores.map((store) => ({
        countryCode: store.value,
        languageCode: store.languageCode,
      })),
    });
    setSelectedStores(stores);
    const currencies = stores
      .map((store) => store.currencyUnit)
      .filter(onlyUnique)
      .sort((a, b) => b.localeCompare(a));

    setAvailableCurrencies(currencies);
  };

  useEffect(() => {
    if (
      !showAdditionalOptions &&
      discountToCreate?.code &&
      discountToCreate?.startDate &&
      discountToCreate?.endDate &&
      discountToCreate?.stores?.length > 0 &&
      discountToCreate?.type
    ) {
      setShowAdditionalOptions(true);
      setTimeout(function () {
        additionalOptionsRef.current.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [discountToCreate, showAdditionalOptions]);

  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  useEffect(() => {
    discountToCreate.type === "FIXED_PRICE" &&
      updateDiscountToCreate({
        discountInFixedPrice: availableCurrencies.map(
          (currencyUnit) =>
            discountToCreate.discountInFixedPrice?.find(
              (option) => option.currencyUnit === currencyUnit
            ) ?? {
              currencyUnit,
              amount: 0,
            }
        ),
        currencyOptions: availableCurrencies.map(
          (currencyUnit) =>
            discountToCreate.currencyOptions?.find(
              (option) => option.currencyUnit === currencyUnit
            ) ?? {
              currencyUnit,
              atLeastTotalPriceWithDiscount: 0,
            }
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCurrencies, discountToCreate.type]);

  const updateDiscountToCreate = (newValue) =>
    setDiscountToCreate({ ...discountToCreate, ...newValue });

  const discountType = discountToCreate.type;

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/discount-codes", "Discount codes"],
          ["admin/add-discount-code", "Add discount codes"],
        ]}
      />
      <Header heading="Discount codes" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={GET_DISCOUNT_TYPES}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );

              const discountTypes = data.__type.enumValues
                .map((v) => v.name)
                .filter((n) => n !== "VOUCHER");

              return (
                <Mutation
                  mutation={ADD_DISCOUNT_CODE}
                  onCompleted={(addDiscount) => {
                    setNotification({
                      status: "success",
                      message: `Discount code successfully added`,
                    });
                    setTabs(() => [...tabs, { id: addDiscount.addDiscount.id, reference: addDiscount.addDiscount.code }]);
                    history.push("/admin/discount-codes");
                  }}
                  onError={(error) => {
                    setNotification({
                      status: "error",
                      message:
                        error.message === "Already exists"
                          ? "Active discount code already exists!"
                          : "An error occurred adding the discount code, please contact support",
                    });
                  }}>

                  {(addDiscount, { loading: addDiscountLoading }) => {
                    const onSubmit = () => {

                      let typeAttribute = {};

                      if (discountType === "CATEGORY") {
                        typeAttribute = {
                          categories: selectedCategories.map((category) => category.value),
                          notForProducts: excludedProducts.products,
                          minItems: minItems,
                          combine: combine,
                        };
                      }

                      if (discountType === "PRODUCT") {
                        typeAttribute = {
                          validForProducts: selectedProducts.products,
                          minItems: minItems,
                          combine: combine,
                        };
                      }

                      if (discountType === "BOGO") {
                        typeAttribute = {
                          notForCategories: excludedCategories.map((category) => category.value),
                          combine: combine,
                        };
                      }

                      if (discountType === "FIXED_PRICE") {
                        typeAttribute = {
                          discountInFixedPrice: discountToCreate.discountInFixedPrice.map(
                            (option) => ({
                              currencyUnit: option.currencyUnit,
                              amount: formatPrice(Number(option.amount), option.currencyUnit),
                            })
                          ),
                          currencyOptions: discountToCreate.currencyOptions.map((option) => ({
                            currencyUnit: option.currencyUnit,
                            atLeastTotalPriceWithDiscount: formatPrice(
                              Number(option.atLeastTotalPriceWithDiscount),
                              option.currencyUnit
                            ),
                          })),
                        };
                      }

                      if (discountType === "FILTER") {
                        typeAttribute = {
                          filterRules: filterRules.map((filterRule) => ({
                            mode: filterRule.mode,
                            key: filterRule.key,
                            values: filterRule.values,
                          })),
                          minItems: minItems,
                          combine: combine,
                        };
                      }

                      if (discountType === "SELECTION") {
                        typeAttribute = {
                          ...(minItems > 0 && {
                            filterRules: filterRules.map((filterRule) => ({
                              mode: filterRule.mode,
                              key: filterRule.key,
                              values: filterRule.values,
                            })),
                          }),
                          discountRules: discountRules.map((discountRule) => ({
                            mode: discountRule.mode,
                            key: discountRule.key,
                            values: discountRule.values,
                          })),
                          maxItems: maxItems,
                          minItems: minItems,
                          ...(minItems > 0 && { recurrent: recurrent }),
                        };
                      }

                      if(discountType === "GWP_THRESHOLD") {
                        typeAttribute = {
                          gifts: gifts.products,
                          currencyOptions
                        }
                      }

                      if(discountType === "GWP_ITEMS") {
                        typeAttribute = {
                          gifts: gifts.products,
                          validForProducts: selectedProducts.products,
                          minItems
                        }
                      }

                     addDiscount({ variables: { ...discountToCreate, ...typeAttribute } }).catch(
                        (e) => {
                          console.log(e);
                        }
                      );
                    }

                    return (
                      <GridItem columns="12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Box
                            preHeading="Discount codes"
                            heading="Add new discount code"
                            subHeading="Discount code to be used by customers in checkout"
                            showGoBackButton
                            goBackOnClick={() => history.push("/admin/discount-codes")}>
                            {addDiscountLoading && <Loader />}
                            {discountToCreate?.type && (
                              <ActionButtons inBox footerOnMobile>
                                <ActionButton type="submit" disabled={!showAdditionalOptions}>
                                  <i className="fal fa-fw fa-check" /> Save
                                </ActionButton>
                              </ActionButtons>
                            )}
                            <GeneralOptions
                              register={register}
                              errors={errors}
                              discountToCreate={discountToCreate}
                              updateDiscountToCreate={updateDiscountToCreate}
                              setValue={setValue}
                              Controller={Controller}
                              control={control}
                              selectedStores={selectedStores}
                              updateSelectedStores={updateSelectedStores}
                            />
                            <TypeLabel>Discount type *</TypeLabel>
                            <TypeButtons
                              discountTypes={discountTypes}
                              currentType={discountToCreate.type}
                              setDiscountType={(type) => updateDiscountToCreate({ type: type })}
                            />
                            <IconWrapper>
                              <DescriptionIcon className="fa-solid fa-circle-info" />
                            </IconWrapper>
                            <div ref={additionalOptionsRef} />
                            <TypeDescription>
                              <p>{getTypeExplanation(discountToCreate.type)}</p>
                            </TypeDescription>
                          </Box>
                          {showAdditionalOptions && (
                            <>
                              <AdditionalOptions
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                minItems={minItems}
                                setMinItems={setMinItems}
                                maxItems={maxItems}
                                setMaxItems={setMaxItems}
                                combine={combine}
                                setCombine={setCombine}
                                discountToCreate={discountToCreate}
                                updateDiscountToCreate={updateDiscountToCreate}
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                excludedCategories={excludedCategories}
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories}
                                setExcludedCategories={setExcludedCategories}
                                excludedProducts={excludedProducts}
                                setExcludedProducts={setExcludedProducts}
                                availableCurrencies={availableCurrencies}
                                gifts={gifts}
                                setGifts={setGifts}
                                currencyOptions={currencyOptions}
                                setCurrencyOptions={setCurrencyOptions}
                                history={history}
                                filterRules={filterRules}
                                discountRules={discountRules}
                                control={control}
                                setFilterRules={setFilterRules}
                                setDiscountRules={setDiscountRules}
                                recurrent={recurrent}
                                setRecurrent={setRecurrent}
                                unregister={unregister}
                              />
                              <SaveBar history={history} />
                            </>
                          )}
                        </form>
                      </GridItem>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};

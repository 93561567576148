import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import { useQuery } from "@apollo/client";
import SEARCH_PRODUCTS from "graphql/Product/SearchProducts";
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import Button from "components/Ui/Button";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Products from "components/Table/Rows";
import Product from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";
import Placeholder from "components/Ui/PlaceholderImage";
import esb from "elastic-builder";

const Header = styled(TableHeader)`
  padding-left: 5rem;
  padding-right: 1rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 1rem 0;
  }
  ${MEDIA_MIN_LARGE} {
    padding-right: 2rem;
    padding-left: 10rem;
  }
`;

const Image = styled.img`
  width: 4rem;
  margin-left: 0;

  ${MEDIA_MIN_LARGE} {
    width: 7rem;
    margin-left: 1rem;
  }
`;

const PlaceholderImage = styled(Placeholder)`
  width: 4rem;
  margin-left: 0;

  ${MEDIA_MIN_LARGE} {
    width: 7rem;
    margin-left: 1rem;
  }
`;

const Name = styled(Column)`
  width: 50%;
  padding: 0 1rem;

  ${MEDIA_MIN_LARGE} {
    width: 50%;
    padding: 0 2rem;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p {
    display: none;
    font-size: 0.9rem;
    ${MEDIA_MIN_LARGE} {
      display: block;
    }
  }
`;

const Category = styled(Column)`
  width: 30%;
`;

const Active = styled(Column)`
  width: 20%;

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }

  .fa-check-circle {
    color: green;
  }

  .fa-times-circle {
    color: red;
  }

  i {
    font-size: 1.8rem;
    margin-right: 0.8rem;

    ${MEDIA_MIN_LARGE} {
      font-size: 2.4rem;
    }
  }
`;

const Date = styled(Column)`
  width: 30%;

  ${MEDIA_MIN_LARGE} {
    width: 20%;
  }
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};

  i {
    font-size: 1.6rem;
  }
`;

export default function ProductsTable({
  productData,
  totalHits,
  loading,
  productsPerPage,
  fetchMore,
  history,
  esbQuery,
  useRelatedProduct,
  hideTotalVariants,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(productsPerPage);
  const [goToPageInput, setGoToPageInput] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("default");
  const [sortField, setSortField] = useState("lastUpdated");
  const [products, setProducts] = useState(productData);
  const productsTotalHits = totalHits;

  useEffect(() => {
    const from = (currentPage - 1) * itemsPerPage;
    fetchMore({
      variables: { query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()) },
    })
      .then((result) => {
        if (!useRelatedProduct) {
          const fetchProducts = result.data.searchProducts.products.map(
            (p) => p.relatedProduct || p
          );
          setProducts(
            Array.from(new Set(fetchProducts.map((a) => a.id))).map((id) => {
              return fetchProducts.find((a) => a.id === id);
            })
          );
        } else {
          setProducts(result.data.searchProducts.products);
        }
      })
      .catch((error) => console.log(error));
    setGoToPageInput(currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, esbQuery]);

  const handleProductClick = (productId) => {
    history.push(`/admin/product/${productId.replaceAll("/", "~")}`);
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField]) || sortField === "active") {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  const allVariants = new esb.requestBodySearch()
    .trackTotalHits(true)
    .query(esb.queryStringQuery("type:productVariant AND !(archived:1)"))
    .size(1);

  const { loading: variantsLoading, data: variantsData } = useQuery(SEARCH_PRODUCTS, {
    variables: { query: JSON.stringify(allVariants.toJSON()) },
  });

  return (
    <>
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={() => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {productsTotalHits ? Math.ceil(productsTotalHits / itemsPerPage) : "-"} pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <HoM>Total </HoM>products: <span>{productsTotalHits || "-"}</span>
        </TotalItems>
        {!hideTotalVariants && (
          <TotalItems>
            <HoM>Total </HoM>variants: <span>{variantsData?.searchProducts?.totalHits || "-"}</span>
          </TotalItems>
        )}
      </Toolbar>
      <Header>
        <Name onClick={() => onSortChange("name")}>
          <span>Name </span>
          <SortButton>
            <i className={`fas fa-${getSortType("name", currentSort)}`} />
          </SortButton>
        </Name>
        <Active onClick={() => onSortChange("active")}>
          <span>Active </span>
          <SortButton>
            <i className={`fas fa-${getSortType("active", currentSort)}`} />
          </SortButton>
        </Active>
        <Category hideOnMobile onClick={() => onSortChange("category")}>
          <span>Category </span>
          <SortButton>
            <i className={`fas fa-${getSortType("category", currentSort)}`} />
          </SortButton>
        </Category>
        <Date onClick={() => onSortChange("lastUpdated")}>
          <span>Last updated </span>
          <SortButton>
            <i className={`fas fa-${getSortType("lastUpdated", currentSort)}`} />
          </SortButton>
        </Date>
      </Header>
      <Products>
        {(loading || variantsLoading) && <Loader />}
        {products && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={productsTotalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...products].sort(sortTypes[currentSort].fn).map((product) => {
              return (
                <Product
                  key={uuid()}
                  onClick={() =>
                    handleProductClick(useRelatedProduct ? product.relatedProduct.id : product.id)
                  }>
                  {product.imageUrl ? (
                    <Image src={`${product.imageUrl}?fm=webp&w=150`} alt={product.name} />
                  ) : (
                    <PlaceholderImage />
                  )}

                  <Name>
                    <NameWrapper>
                      <strong>{product.name}</strong>
                      <p>{product.id}</p>
                    </NameWrapper>
                  </Name>
                  <Active>
                    <i className={product.active ? "fal fa-check-circle" : "fal fa-times-circle"} />
                  </Active>
                  <Category hideOnMobile>{product.category}</Category>
                  <Date>
                    <Moment format="YYYY-MM-DD, HH:mm">{product.lastUpdated}</Moment>
                  </Date>
                </Product>
              );
            })}
          </Pagination>
        )}
      </Products>
    </>
  );
}

import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import esb from "elastic-builder";
import moment from "moment-timezone";
import { useAdminContext } from "context/AdminContext";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import QuickFilter from "components/Table/Filter/QuickFilter";
import QuickFilterOption from "components/Table/Filter/QuickFilterOption";
import SearchForm from "components/Table/Search/SearchForm";
import ClearButton from "components/Table/Filter/ClearButton";
import Timespan from "components/Table/Filter/Timespan";
import StoreSelector from "components/StoreSelector/StoreSelector";
import FilterButton from "components/Ui/FilterButton";
import FilterForm from "components/Filter/FilterForm";
import Select from "components/Ui/Select";

const Buttons = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  ${MEDIA_MIN_LARGE} {
    padding: 0;
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_LARGE} {
    width: auto;
    padding-bottom: 0;
  }
`;

const StoreFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  div {
    margin: 0;
  }

  ${MEDIA_MIN_LARGE} {
    width: 60%;
    max-width: 50rem;
    margin-left: 1rem;
  }
`;

const IncludeSelect = styled(Select)`
  display: flex;
  flex-wrap: wrap;
  min-width: 15rem;

  ${MEDIA_MIN_LARGE} {
    margin-left: 1rem;
    flex-wrap: nowrap;
  }
`;

const StoreSelect = styled(StoreSelector)`
  .multi-select {
    .dropdown-heading {
      height: 4rem;
    }
  }
`;

const INCLUDE_LIST = [
  { value: "created", label: "Created" },
  { value: "lastUpdated", label: "Last Updated" },
];
const FILTER_STATUSES = [
  { value: "success", label: "Success" },
  { value: "shipped", label: "Shipped" },
  { value: "refunded", label: "Refunded" },
  { value: "canceled", label: "Canceled" },
  { value: "failed", label: "Failed" },
  { value: "waiting", label: "Waiting" },
];

export default ({ setEsbQuery }) => {
  const { ALL_ORDERS, orderFilter, updateOrderFilter, clearOrderFilter } = useAdminContext();
  const [defaultQuery] = useState(ALL_ORDERS);
  const [inputError, setInputError] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const searchOrders = (event) => {
    event.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    if (/^\d+$/.test(searchInput.trim())) {
      setEsbQuery(
        new esb.requestBodySearch().query(
          esb
            .boolQuery()
            .must([
              esb.matchQuery("statusLog.status", "success"),
              esb.queryStringQuery(searchInput.trim()).fuzziness("AUTO").fields(["reference"]),
            ])
        )
      );
    } else if (searchInput && searchInput.replace(/\s/g, "") !== "") {
      setEsbQuery(
        new esb.requestBodySearch().query(
          esb
            .boolQuery()
            .must([
              esb.matchQuery("statusLog.status", "success"),
              esb.queryStringQuery(formatInput(searchInput)).analyzeWildcard(),
            ])
        )
      );
    } else {
      setSearchInput("");
      setEsbQuery(defaultQuery);
    }
  };

  const formatInput = (input) => {
    if (/^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}-){3})([0-9a-fA-F]{12})$/.test(input)) {
      return `"${input}"`;
    }
    return input.replace("@", "*");
  };

  const filterOrders = (event) => {
    event && event.preventDefault();
    const boolQuery = new esb.boolQuery()
      .must([esb.matchQuery("statusLog.status", "success")])
      .must(
        esb
          .rangeQuery(orderFilter.include)
          .gte(orderFilter.filterDateTimeFrom.replace("T", "-"))
          .lte(orderFilter.filterDateTimeTo.replace("T", "-"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(moment.tz.guess())
      );
    if (orderFilter.filterStatus.length) {
      boolQuery.must(esb.termsQuery("status", orderFilter.filterStatus));
    }
    if (orderFilter.filterStores.length) {
      boolQuery.must(
        esb.termsQuery(
          "shippingAddress.country",
          orderFilter.filterStores.map((s) => s.value.toLowerCase())
        )
      );
    }

    setEsbQuery(
      esb
        .requestBodySearch()
        .query(boolQuery)
        .sort(esb.sort("lastUpdated", "desc"))
        .size(orderFilter.ordersPerPage)
        .from(0)
        .trackTotalHits(true)
    );
  };

  const handleChangeInclude = (event) => {
    event.preventDefault();
    updateOrderFilter("include", event.target.value);
  };

  const clearFilter = (event) => {
    event && event.preventDefault();
    clearFilterInput();
    setEsbQuery(defaultQuery);
  };

  const clearFilterInput = () => {
    clearOrderFilter();
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    filterOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFilter.filterStatus]);

  return (
    <>
      <SearchForm
        autoComplete="off"
        placeholder="Order number, e-mail, name..., (Use * as wildcard)"
        inputError={inputError}
        setInputError={setInputError}
        setSearchInput={setSearchInput}
        searchFunc={searchOrders}
        defaultValue={searchInput}
      />
      <QuickFilter expanded={"false"}>
        Filter:
        <div>
          <QuickFilterOption
            active={orderFilter.filterStatus.length === 0}
            onClick={() => {
              updateOrderFilter("filterStatus", []);
            }}>
            All
          </QuickFilterOption>
          {FILTER_STATUSES.map((status) => (
            <QuickFilterOption
              key={status.value}
              active={orderFilter.filterStatus.includes(status.value)}
              onClick={() => {
                updateOrderFilter(
                  "filterStatus",
                  orderFilter.filterStatus.includes(status.value)
                    ? orderFilter.filterStatus.filter((value) => value !== status.value)
                    : [...orderFilter.filterStatus, status.value]
                );
              }}>
              {status.label}
            </QuickFilterOption>
          ))}
        </div>
      </QuickFilter>
      <FilterForm>
        <Timespan>
          <div>
            <Label>From:</Label>
            <input
              type="datetime-local"
              id="fromDate"
              name="fromDate"
              value={orderFilter.filterDateTimeFrom}
              onChange={(e) => updateOrderFilter("filterDateTimeFrom", e.target.value)}
            />
          </div>
          <div>
            <Label>To:</Label>
            <input
              type="datetime-local"
              id="toDate"
              name="toDate"
              value={orderFilter.filterDateTimeTo}
              onChange={(e) => updateOrderFilter("filterDateTimeTo", e.target.value)}
            />
          </div>
          <div>
            <Label>Date on:</Label>
            <IncludeSelect handleChange={handleChangeInclude}>
              {INCLUDE_LIST.map((s) => (
                <option key={s.value} value={s.value}>
                  {s.label}
                </option>
              ))}
            </IncludeSelect>
          </div>
        </Timespan>
        <StoreFilter>
          <StoreSelect
            showContinents
            dropDownTop="5.5rem"
            small
            selectedStores={orderFilter.filterStores}
            setSelectedStores={(value) => updateOrderFilter("filterStores", value)}
          />
        </StoreFilter>
        <Buttons>
          <FilterButton isLoading={false} onClick={filterOrders}>
            <i className="fal fa-sliders-h"></i> Filter
          </FilterButton>
          <ClearButton onClick={clearFilter} />
        </Buttons>
      </FilterForm>
    </>
  );
};

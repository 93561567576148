import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client/react/hooks";
import REFUND_ORDER from "graphql/Order/RefundOrder";
import UPDATE_ORDER_REFUND_FEE from "graphql/Order/UpdateOrderRefundFee";
import UPDATE_ORDER_REFUND_ACTIVE from "graphql/Order/UpdateOrderRefundActive";
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Box from "components/Content/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Money from "components/Money/Money";
import ActionButtons from "components/ActionButtons/ActionButtons";
import RefundButton from "components/ActionButtons/RefundButton";
import Checkbox from "components/Ui/Checkbox";
import Loader from "components/Ui/Loader";
import Toggle from "components/Ui/Toggle";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Input from "components/Ui/Input";
import Dialog from "components/Dialog/Dialog";
import isRefundable from "helpers/isRefundable";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import TableHeader from "components/Table/Header";
import Column from "components/Table/Column";
import { useNotification } from "context/NotificationContext";
import Options from "./Options";
import HoM from "components/Ui/HoM";
import ButtonOutlined from "components/Ui/ButtonOutlined";
import getRefundFeeActive from "helpers/getRefundFeeActive";

const Header = styled(TableHeader)`
  padding-right: 0;

  > div {
    &:first-child {
      margin-left: ${(p) => (p.isRefundable ? "7rem" : "4rem")};

      ${MEDIA_MIN_MEDIUM} {
        margin-left: ${(p) => (p.isRefundable ? "11rem" : "7rem")};
      }
    }
  }
`;

const RefundCheckbox = styled(Checkbox)`
  pointer-events: ${(p) => (p.disabled ? "none" : "all")};
  background: ${(p) => (p.disabled ? p.theme.colors.greyOpac : p.theme.colors.black)};
  margin-right: 2rem;
  visibility: ${(p) => (p.disabled ? "hidden" : "visible")};
`;

const Image = styled.img`
  width: 5rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 7rem;
  }
`;

const Name = styled(Column)`
  width: 640%;
  padding-left: 2rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${MEDIA_MIN_MEDIUM} {
    width: 20%;
  }
`;

const UnitPrice = styled(Column)`
  width: 10%;
`;

const Quantity = styled(Column)`
  width: 10%;
  padding-right: 1rem;

  i {
    color: ${(p) => p.theme.colors.ligthGrey};
    font-size: 1.8rem;
    position: absolute;
    right: 40rem;
    margin-left: 0.5rem;
  }
`;

const TaxRate = styled(Column)`
  width: 10%;
`;

const TotalTax = styled(Column)`
  width: 10%;
`;

const Discount = styled(Column)`
  width: 10%;
`;

const TotalAmount = styled(Column)`
  width: 20%;
`;

const Lines = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Wrapper = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
`;

const Line = styled.li`
  width: 100%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  margin: 0;
  position: relative;
  overflow: hidden;
  background: ${(p) => p.optionsExpanded && p.theme.colors.greyOpac};

  ${MEDIA_MIN_MEDIUM} {
    padding-left: 2rem;
  }
`;

const AddonLine = styled.li`
  width: 100%;
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: ${(p) => p.optionsExpanded && p.theme.colors.greyOpac};

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 0 2rem 2rem;
    div {
      margin-bottom: 1rem;
    }
  }
`;

const OptionsButton = styled.div`
  min-height: 3rem;
  min-width: 3rem;
  margin-right: 4rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA_MIN_MEDIUM} {
    transition: background 0.3s;
    border-radius: 50%;
    &:hover {
      background: ${(p) => p.theme.colors.primary};
      color: white;
      cursor: pointer;
    }
  }
`;

const Refunded = styled.span`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.buttonText};
  text-transform: initial;
  margin-left: 1rem;
  padding: 0 2.5rem;
  transform: rotate(-45deg);
  z-index: 1;
  position: absolute;
  left: -3.7rem;
  font-size: 1.2rem;
  top: 1.7rem;
  pointer-events: none;
`;

const PartRefunded = styled(Refunded)`
  background: ${(p) => p.theme.colors.yellow};
`;

const RefundWrapper = styled.div``;

const Error = styled.p`
  color: red;
`;

const ReasonButton = styled(ButtonOutlined)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0 1.8rem;
  padding: 2rem 1.5rem 2rem 1rem;
`;

const ReasonWrapper = styled.span`
  width: 100%;
  overflow: hidden;
  height: ${(p) => (p.showReason ? "20rem" : "0")};
  transition: height 0.3s ease-out;
  label {
    font-weight: 400;
  }
  input {
    margin-bottom: 2rem;
  }
`;

const Totals = styled(Attributes)`
  width: 100%;
`;

const Total = styled(Attribute)`
  width: 100%;
  justify-content: space-between;
`;

const TotalPaid = styled(Total)`
  font-size: 1.6rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.8rem;
  }
`;

const TotalValue = styled(Value)`
  justify-content: flex-end;
`;

const TotalLabel = styled(Label)`
  width: 25rem;
`;

const AddonIcon = styled.i`
  font-size: 2.5rem;
  margin-right: 2.3rem;
  ${MEDIA_MIN_MEDIUM} {
    padding-left: 2rem;
  }
`;

const ToggleLabel = styled.span`
  width: 10rem;
  margin-left: 1rem;
`;

const NotRefunded = styled.span`
  position: relative;
  top: -1rem;
  left: 3rem;
  font-size: 1.2rem;
  font-weight: 700;
  i {
    margin-left: 0.5rem;
    padding: 0.3rem;
  }

  &:hover {
    cursor: pointer;

    i {
      background: ${(p) => p.theme.colors.yellow};
    }
  }
`;

const RefundActions = styled(ActionButtons)`
  position: relative;
  padding: 1rem 0 2rem 0;
  top: auto;
  right: 0;

  ${MEDIA_MIN_LARGE} {
    position: absolute;
    padding: 2rem 0;
    top: 4rem;
    right: 2rem;
  }
`;

const RefundRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RefundImage = styled.img`
  width: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 4rem;
  }
`;

const QuantityInput = styled.input`
  width: 4rem;
`;

const Refund = styled.div`
  display: flex;
`;

const RefundInfo = styled.span`
  display: flex;
  padding-bottom: 1rem;
`;

const RefundedDot = styled.div`
  width: 2rem;
  height: 2rem;
  background: ${(p) => (p.full ? p.theme.colors.primary : p.theme.colors.yellow)};
  border-radius: 50%;
  margin-right: 1rem;
  margin-left: ${(p) => (p.full ? "0" : "1rem")};
`;

const isEmpty = (value) => typeof value === "undefined" || value === null;

const hideOrderLineTypes = ["shipment", "refund"];

export default ({ order, refetch }) => {
  const currencyUnit = order.currencyUnit;
  const history = useHistory();
  const [refundOrderLines, setRefundOrderLines] = useState(new Map());
  const [disabled, setDisabled] = useState(true);
  const [refundedOrderLines, setRefundedOrderLines] = useState({});
  const [refundReason, setRefundReason] = useState();
  const [refundQuantity, setRefundQuantity] = useState({});
  const [initialRefundQuantity, setInitialRefundQuantity] = useState({});
  const [open, setOpen] = useState(false);
  const [refundActive, setRefundActive] = useState(() =>
    isEmpty(order.refundActive) ? true : order.refundActive
  );
  const [refundFeeActive, setRefundFeeActive] = useState(
    getRefundFeeActive() ? (isEmpty(order.refundFee) ? true : order.refundFee) : false
  );
  const [showReason, setShowReason] = useState(false);
  const [showError, setShowError] = useState(false);
  const { setNotification } = useNotification();

  useEffect(() => {
    const refundOrderLines = order?.orderLines.filter((o) => o.type === "refund");

    const productLeftToRefund = refundOrderLines.map((ro) => ({
      [ro.refundOfOrderLine]: order?.orderLines
        ?.filter((ol) => ol.id === ro.refundOfOrderLine || ol.id === ro.id)
        .reduce((acc, curr) => acc + curr.quantity, 0),
    }));

    setRefundQuantity(productLeftToRefund.reduce((a, b) => Object.assign(a, b), {}));
    setInitialRefundQuantity(productLeftToRefund.reduce((a, b) => Object.assign(a, b), {}));
  }, [refundedOrderLines, order.orderLines]);

  const [refundOrder, { loading: refundLoading, error: refundError }] = useMutation(REFUND_ORDER, {
    onCompleted: (data) => {
      setNotification({
        status: "success",
        message: `Order "${data.refundOrder.id}" successfully refunded`,
      });
      setRefundOrderLines(new Map());
    },
    onError: () => {
      setNotification({
        status: "error",
        message: `Order lines could not be refunded, please contact support`,
      });
    },
    refetchQueries: refetch,
  });

  const [refundActiveOrder, { loading: refundActiveLoading, error: refundActiveError }] =
    useMutation(UPDATE_ORDER_REFUND_ACTIVE, {
      onCompleted: (data) => {
        setRefundActive(data.updateOrder.refundActive);
        setNotification({
          status: "success",
          message: `Refund is ${data.updateOrder.refundActive ? "activated" : "inactivated"}`,
        });
      },
      onError: () => {
        setNotification({
          status: "error",
          message: `Refund active could not be set, please contact support`,
        });
      },
      refetchQueries: refetch,
    });

  const [refundFeeOrder, { loading: refundFeeLoading, error: refundFeeError }] = useMutation(
    UPDATE_ORDER_REFUND_FEE,
    {
      onCompleted: (data) => {
        setRefundFeeActive(data.updateOrder.refundFee);
        setNotification({
          status: "success",
          message: `Refundfee is ${data.updateOrder.refundFee ? "activated" : "inactivated"}`,
        });
      },
      onError: () => {
        setNotification({
          status: "error",
          message: `Refundfee could not be set, please contact support`,
        });
      },
      refetchQueries: refetch,
    }
  );

  useEffect(() => {
    setOpen(false);
    setRefundedOrderLines(
      order.orderLines
        .filter(({ type }) => type === "refund")
        .reduce((refunded, orderLine) => {
          const refundedId =
            orderLine.refundOfOrderLine ??
            order.orderLines.find(
              (line) =>
                line.product.id === orderLine.product.id && !hideOrderLineTypes.includes(line.type)
            ).id;
          refunded[refundedId] = true;
          return refunded;
        }, {})
    );
  }, [order, setOpen]);

  const handleProductClick = (productId, variantId) => {
    history.push(`/admin/product/${productId.replaceAll("/", "~")}`, { data: variantId });
  };

  const handleAddonClick = (addonId) => {
    history.push(`/admin/addon/${addonId.replaceAll("/", "~")}`);
  };

  const addonOrderLine = (id) => {
    const addonLine = order.orderLines
      .filter((ol) => ol.type === "addon")
      .find((ol) => ol.relatedOrderLine === id);
    if (addonLine) return addonLine.id;
  };

  const handleCheckbox = async (event) => {
    event.stopPropagation();
    const id = event.target.name;
    const isChecked = event.target.checked;
    const addonId = addonOrderLine(id);
    await setRefundOrderLines((prevState) => prevState.set(id, isChecked));
    if (addonId) await setRefundOrderLines((prevState) => prevState.set(addonId, isChecked));
    setDisabled([...refundOrderLines].filter(([_, v]) => v).length === 0);
  };

  const handleRefundOrder = () => {
    const variables = {
      id: order.id,
      ...(refundReason && { reason: refundReason }),
      orderLinesToBeRefunded: [...refundOrderLines]
        .filter(([_, v]) => v)
        .map(([k, _]) => {
          const orderLine = order.orderLines.find(
            (line) => line.id === k && !hideOrderLineTypes.includes(line.type)
          );
          return {
            ...{ id: orderLine.id, quantity: refundQuantity[orderLine.id] ?? orderLine.quantity },
          };
        }),
    };
    if (variables.orderLinesToBeRefunded.length) {
      setOpen(false);
      refundOrder({ variables: variables });
    }
  };

  const handleRefundActive = () => {
    refundActiveOrder({
      variables: { id: order.id, refundActive: isEmpty(order.refundActive) ? true : !refundActive },
    });
  };

  const handleRefundFee = () => {
    refundFeeOrder({
      variables: { id: order.id, refundFee: isEmpty(order.refundFee) ? false : !refundFeeActive },
    });
  };

  const handleRefundQuantity = (id, quantity) => {
    const addonId = addonOrderLine(id);
    setRefundQuantity({
      ...refundQuantity,
      ...{ [id]: quantity },
      ...(addonId ? { [addonId]: quantity } : {}),
    });
  };

  const handleOk = () => {
    if (!refundReason || refundReason.cause) {
      setShowError(false);
      return handleRefundOrder();
    } else {
      setShowError(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRefundQuantity({});
  };

  const orderLines = (order.orderLines || []).filter(({ type }) => {
    return ![...hideOrderLineTypes, "addon"].includes(type);
  });

  const addonOrderLines = (order.orderLines || []).filter(({ type }) => type === "addon");
  return (
    <>
      {(refundLoading || refundFeeLoading || refundActiveLoading) && <Loader />}
      {(refundError || refundFeeError || refundActiveError) && (
        <ErrorMessage>An error occurred when loading data, please contact support</ErrorMessage>
      )}

      <Box preHeading="Order" heading="Products" headingIcon="box-full">
        {Object.keys(refundedOrderLines).length > 0 && (
          <RefundInfo>
            <RefundedDot full /> Fully Refunded
            <RefundedDot />
            Partially Refunded
          </RefundInfo>
        )}

        <RefundActions inBox>
          {isRefundable(order) && (
            <Refund>
              <Value>
                <ToggleLabel>Refund active:</ToggleLabel>
                <Toggle active={refundActive} handleToggle={() => handleRefundActive()} />
              </Value>
              {getRefundFeeActive() && (
                <Value>
                  <ToggleLabel>Refund fee:</ToggleLabel>
                  <Toggle
                    data-tip="Should a refund fee be added?"
                    active={refundFeeActive}
                    handleToggle={() => handleRefundFee()}
                  />
                </Value>
              )}
              <RefundButton disabled={disabled} type="button" handleOnClick={handleClickOpen}>
                <HoM>Refund Selected</HoM>
              </RefundButton>
              <Dialog
                header="Refund order?"
                text="Refund selected orderlines"
                open={open}
                handleClose={handleClose}
                handleOk={handleOk}>
                {orderLines
                  .filter(({ id }) => refundOrderLines.get(id))
                  .map(({ id, product, imageUrl, quantity, price }) => {
                    const notRefundedQuantity = initialRefundQuantity[id] ?? quantity;
                    return (
                      <RefundRow key={id}>
                        <Quantity>
                          x
                          {notRefundedQuantity > 1 ? (
                            <>
                              <QuantityInput
                                onChange={(e) => handleRefundQuantity(id, e.target.value)}
                                min="1"
                                max={notRefundedQuantity}
                                defaultValue={notRefundedQuantity}
                                type="number"
                              />
                              <i className="fa-thin fa-pen-to-square"></i>
                            </>
                          ) : (
                            notRefundedQuantity
                          )}
                        </Quantity>
                        <RefundImage src={`${imageUrl ?? product.imageUrl}?fm=webp&w=150`} />
                        <div>{product.name}</div>
                        <div>
                          <Money amount={price} currencyUnit={currencyUnit} />
                        </div>
                      </RefundRow>
                    );
                  })}

                <ReasonButton onClick={() => setShowReason(!showReason)}>
                  Add Reason
                  <i className={`fa-light fa-chevron-${showReason ? "up" : "down"}`}></i>
                </ReasonButton>
                <ReasonWrapper showReason={showReason}>
                  <Input
                    label="Refund code"
                    onChange={(e) => setRefundReason({ ...refundReason, code: e.target.value })}
                  />
                  <Input
                    label="Refund cause"
                    onChange={(e) => setRefundReason({ ...refundReason, cause: e.target.value })}
                  />
                </ReasonWrapper>
                {showError && <Error showError={showError}>Cause is required</Error>}
              </Dialog>
            </Refund>
          )}
        </RefundActions>

        <Header isRefundable={isRefundable(order)}>
          <Name>Name</Name>
          <UnitPrice hideOnMobile>Unit price</UnitPrice>
          <Quantity>Qty</Quantity>
          <TaxRate hideOnMobile>Tax rate</TaxRate>
          <TotalAmount hideOnMobile>Subtotal</TotalAmount>
          <Discount hideOnMobile>Discount</Discount>
          <TotalTax hideOnMobile>Total tax</TotalTax>
          <TotalAmount hideOnMobile>Total</TotalAmount>
        </Header>
        <Lines>
          {orderLines.map(
            ({
              id,
              product,
              imageUrl,
              quantity,
              taxRate,
              externalTaxRate,
              totalTaxAmount,
              totalAmount,
              totalAmountWithDiscount,
              price,
              options,
              type,
            }) => {
              const [optionsExpanded, setOptionsExpanded] = useState(false);
              const [additionalRefundExpanded, setAdditionalRefundExpanded] = useState(false);

              return (
                <Wrapper key={id}>
                  <Line optionsExpanded={optionsExpanded}>
                    {refundedOrderLines[id] &&
                      (refundQuantity[id] !== quantity && refundQuantity[id] > 0 ? (
                        <PartRefunded>Refunded</PartRefunded>
                      ) : (
                        <Refunded>Refunded</Refunded>
                      ))}
                    {isRefundable(order) && (
                      <RefundCheckbox
                        disabled={
                          hideOrderLineTypes.includes(type) ||
                          refundedOrderLines[id] ||
                          !order.refundActive
                        }
                        name={id}
                        onClick={handleCheckbox}
                      />
                    )}
                    {options && options.length > 0 && (
                      <OptionsButton onClick={() => setOptionsExpanded(!optionsExpanded)}>
                        {optionsExpanded ? (
                          <i className="fa-regular fa-chevron-right"></i>
                        ) : (
                          <i className="fa-regular fa-chevron-down"></i>
                        )}
                      </OptionsButton>
                    )}
                    <Image src={`${imageUrl ?? product.imageUrl}?fm=webp&w=150`} />
                    <Name onClick={() => handleProductClick(product.relatedProduct.id, product.id)}>
                      {product.name}
                    </Name>
                    <UnitPrice hideOnMobile>
                      <Money amount={price} currencyUnit={currencyUnit} />
                    </UnitPrice>
                    <Quantity>{quantity}</Quantity>
                    <TaxRate hideOnMobile>
                      {externalTaxRate !== null ? (externalTaxRate * 100).toFixed(2) : taxRate} %
                    </TaxRate>
                    <TotalAmount hideOnMobile>
                      <Money amount={totalAmount} currencyUnit={currencyUnit} />
                    </TotalAmount>
                    <Discount hideOnMobile>
                      {"- "}
                      <Money
                        amount={totalAmount - totalAmountWithDiscount}
                        currencyUnit={currencyUnit}
                      />
                    </Discount>
                    <TotalTax hideOnMobile>
                      <Money amount={totalTaxAmount} currencyUnit={currencyUnit} />
                    </TotalTax>
                    <TotalAmount hideOnMobile>
                      <Money
                        amount={
                          order.taxIncludedPricing === false
                            ? totalAmountWithDiscount + totalTaxAmount
                            : totalAmountWithDiscount
                        }
                        currencyUnit={currencyUnit}
                      />
                    </TotalAmount>
                  </Line>

                  <Options options={options} expanded={optionsExpanded} />

                  {addonOrderLines
                    .filter((line) => line.relatedOrderLine === id)
                    .map(
                      ({
                        id,
                        name,
                        quantity,
                        taxRate,
                        externalTaxRate,
                        totalTaxAmount,
                        totalAmount,
                        totalAmountWithDiscount,
                        price,
                        options,
                        product,
                      }) => {
                        const [optionsExpanded, setOptionsExpanded] = useState(false);
                        return (
                          <>
                            <AddonLine optionsExpanded={optionsExpanded} key={id}>
                              {options && options.length > 0 && (
                                <OptionsButton onClick={() => setOptionsExpanded(!optionsExpanded)}>
                                  {optionsExpanded ? (
                                    <i className="fa-regular fa-chevron-right"></i>
                                  ) : (
                                    <i className="fa-regular fa-chevron-down"></i>
                                  )}
                                </OptionsButton>
                              )}
                              <AddonIcon className="fa-light fa-circle-plus" />
                              <Name onClick={() => handleAddonClick(product.id)}>{name}</Name>
                              <UnitPrice hideOnMobile>
                                <Money amount={price} currencyUnit={currencyUnit} />
                              </UnitPrice>
                              <Quantity>{quantity}</Quantity>
                              <TaxRate hideOnMobile>
                                {externalTaxRate != null && externalTaxRate >= 0
                                  ? (externalTaxRate * 100).toFixed(2)
                                  : taxRate}{" "}
                                %
                              </TaxRate>
                              <TotalAmount hideOnMobile>
                                <Money amount={totalAmount} currencyUnit={currencyUnit} />
                              </TotalAmount>
                              <Discount hideOnMobile>
                                {"- "}
                                <Money
                                  amount={totalAmount - totalAmountWithDiscount}
                                  currencyUnit={currencyUnit}
                                />
                              </Discount>
                              <TotalTax hideOnMobile>
                                <Money amount={totalTaxAmount} currencyUnit={currencyUnit} />
                              </TotalTax>
                              <TotalAmount hideOnMobile>
                                <Money
                                  amount={
                                    order.taxIncludedPricing === false
                                      ? totalAmountWithDiscount + totalTaxAmount
                                      : totalAmountWithDiscount
                                  }
                                  currencyUnit={currencyUnit}
                                />
                              </TotalAmount>
                            </AddonLine>
                            <Options options={options} expanded={optionsExpanded} />
                          </>
                        );
                      }
                    )}

                  {refundedOrderLines[id] &&
                    refundQuantity[id] !== quantity &&
                    refundQuantity[id] > 0 && (
                      <>
                        <NotRefunded
                          onClick={() => setAdditionalRefundExpanded(!additionalRefundExpanded)}>
                          Additional refund on product
                          {additionalRefundExpanded ? (
                            <i className="fa-regular fa-chevron-up"></i>
                          ) : (
                            <i className="fa-regular fa-chevron-down"></i>
                          )}
                        </NotRefunded>

                        {additionalRefundExpanded && (
                          <RefundWrapper>
                            <Line optionsExpanded={optionsExpanded}>
                              {isRefundable(order) && (
                                <RefundCheckbox
                                  disabled={
                                    hideOrderLineTypes.includes(type) || !order.refundActive
                                  }
                                  name={id}
                                  onClick={handleCheckbox}
                                />
                              )}
                              {options && options.length > 0 && (
                                <OptionsButton onClick={() => setOptionsExpanded(!optionsExpanded)}>
                                  {optionsExpanded ? (
                                    <i className="fa-regular fa-chevron-right"></i>
                                  ) : (
                                    <i className="fa-regular fa-chevron-down"></i>
                                  )}
                                </OptionsButton>
                              )}
                              <Image src={`${imageUrl ?? product.imageUrl}?fm=webp&w=150`} />
                              <Name
                                onClick={() =>
                                  handleProductClick(product.relatedProduct.id, product.id)
                                }>
                                {product.name}
                              </Name>
                              <UnitPrice hideOnMobile>
                                <Money amount={price} currencyUnit={currencyUnit} />
                              </UnitPrice>
                              <Quantity>{refundQuantity[id]}</Quantity>
                              <TaxRate hideOnMobile></TaxRate>
                              <TotalAmount hideOnMobile></TotalAmount>
                              <Discount hideOnMobile></Discount>
                              <TotalTax hideOnMobile></TotalTax>
                              <TotalAmount hideOnMobile></TotalAmount>
                            </Line>
                          </RefundWrapper>
                        )}
                      </>
                    )}
                </Wrapper>
              );
            }
          )}
        </Lines>

        <GridContainer collapse padding="2rem 0 0">
          <GridItem columns="6"></GridItem>
          <GridItem columns="6" padding="0">
            <Totals>
              <Total>
                <TotalLabel>Subtotal:</TotalLabel>
                <TotalValue>
                  <Money amount={order.orderAmount} currencyUnit={order.currencyUnit} />
                </TotalValue>
              </Total>
              <Total>
                <TotalLabel>Discount:</TotalLabel>
                <TotalValue>
                  {"- "}
                  <Money
                    amount={order.orderAmount - order.orderAmountWithDiscount}
                    currencyUnit={order.currencyUnit}
                  />
                </TotalValue>
              </Total>
              {order.taxIncludedPricing === false && (
                <Total>
                  <TotalLabel>Tax:</TotalLabel>
                  <TotalValue>
                    <Money amount={order.orderTaxAmount} currencyUnit={order.currencyUnit} />
                  </TotalValue>
                </Total>
              )}
              <TotalPaid>
                <TotalLabel>Total order value:</TotalLabel>
                <TotalValue>
                  <Money
                    amount={
                      order.taxIncludedPricing === false
                        ? order.orderAmountWithDiscount + order.orderTaxAmount
                        : order.orderAmountWithDiscount
                    }
                    currencyUnit={order.currencyUnit}
                  />
                </TotalValue>
              </TotalPaid>
              {order.taxIncludedPricing !== false && (
                <Total>
                  <TotalLabel>Tax:</TotalLabel>
                  <TotalValue>
                    <Money amount={order.orderTaxAmount} currencyUnit={order.currencyUnit} />
                  </TotalValue>
                </Total>
              )}
            </Totals>
          </GridItem>
        </GridContainer>
      </Box>
    </>
  );
};

import React, { useState } from "react";
import esb from "elastic-builder";
import SearchForm from "components/Table/Search/SearchForm";


export default ({ setEsbQuery }) => {
  const [searchInput, setSearchInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const searchVouchers = (evt) => {
    evt.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    setEsbQuery(
      new esb.requestBodySearch()
        .query(esb.queryStringQuery("!(archived:1) AND (type:VOUCHER)"))
        .query(
          esb
            .queryStringQuery(
              searchInput
                .split(" ")
                .join("* ")
                .concat("*")
                .concat(" AND !(archived:1)")
            )
            .fields(["group"])
        )
        .agg(
          esb
            .termsAggregation("groups", "group.keyword")
            .size(1000)
            .agg(esb.dateHistogramAggregation("created", "created", "1h"))
        )
    );
  };
  return (
      <SearchForm
        placeholder="Voucher group name"
        inputError={inputError}
        setInputError={setInputError}
        setSearchInput={setSearchInput}
        searchFunc={searchVouchers}
      />
  );
};

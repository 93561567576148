import React, { useState } from "react";
import styled from "styled-components/macro";
import { useForm, Controller } from "react-hook-form-old";
import { Query, Mutation } from "@apollo/client/react/components";
import uuid from "react-uuid";

import GET_CONFIGURATION from "graphql/Configuration/GetConfiguration";
import ADD_OR_UPDATE_WEBHOOK_CONFIGURATION from "graphql/Configuration/AddOrUpdateWebhookConfiguration";

import { useNotification } from "context/NotificationContext";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Sidebar from "components/Configuration/Sidebar";
import DeleteWebhookConfiguration from "components/Configuration/DeleteWebhookConfiguration";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import IconButton from "components/Ui/Buttons/IconButton";
import TableHeader from "components/Table/Header";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import Input from "components/Ui/Input";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const Webhook = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 6rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }

  h5 {
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    padding: 0 0 3rem;
    width: 100%;
    margin: 3rem 0 0;
  }
`;

const Fieldset = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    width: 100%;
  }

  ${MEDIA_MIN_LARGE} {
    > div {
      width: 49%;
    }
  }
`;

const Headers = styled.div`
  width: 100%;
  position: relative;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  border-top: none;

  > div {
    width: 47%;
    margin: 0;
  }

  ${MEDIA_MIN_LARGE} {
    padding: 2rem;
  }
`;

const AddHeaderButton = styled(ActionButtonSecondary)`
  position: absolute;
  top: 0.7rem;
  right: 0;
`;

const Padding = styled.div`
  width: 4rem !important;
`;

export default (props) => {
  const { control, handleSubmit, errors } = useForm();
  const { setNotification } = useNotification();
  const [id] = useState(props.match.params.id);
  const [webhook, setWebhook] = useState(null);
  const [headers, setHeaders] = useState({});

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/configuratios", "Configurations"],
          ["admin/webhook-outOfStock", "Out of stock webhooks"],
          ["admin/webhook-outOfStock", "Edit webhook"],
        ]}
      />
      <Header heading="Configurations" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query
            query={GET_CONFIGURATION}
            variables={{ service: "brink" }}
            onCompleted={(data) => {
              const webhookMatch = data.getConfiguration.find((config) => config.name === id);
              const webhookData = JSON.parse(webhookMatch.data);
              setHeaders(webhookData.headers);
              setWebhook({
                name: webhookMatch.name,
                url: webhookData.url,
              });
            }}>
            {({ loading: loadingConfig, error: errorConfig }) => {
              if (loadingConfig || !webhook) return <Loader />;
              if (errorConfig)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );

              const getName = (name) => name.split(".")[2]
              return (
                <Mutation
                  mutation={ADD_OR_UPDATE_WEBHOOK_CONFIGURATION}
                  onCompleted={() => {
                    setNotification({
                      status: "success",
                      message: "Configuration successfully saved",
                    });
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message: "Could not save configuration. Please contact support",
                    });
                  }}>
                  {(addOrUpdateWebhookConfigurations, { loading: updateLoading }) => {
                    const onSubmit = (data) => {
                      const headerObject = {};
                      data.headers &&
                        Object.keys(data.headers).forEach(
                          (key) => (headerObject[data.headers[key].key] = data.headers[key].value)
                        );

                      addOrUpdateWebhookConfigurations({
                        variables: {
                          name: getName(data.name),
                          type: "outOfStock",
                          data: {
                            url: data.url,
                            headers: JSON.stringify(headerObject),
                          },
                        },
                      }).catch((e) => {
                        console.log(e);
                      });
                    };

                    return (
                      <GridItem columns="12">
                        <Box
                          preHeading="Webhooks"
                          heading="Edit webhook"
                          subHeading={webhook.name}
                          showGoBackButton
                          goBackOnClick={() => props.history.push("/admin/webhook-outOfStock")}>
                          {updateLoading && <Loader />}
                          <Webhook onSubmit={handleSubmit(onSubmit)}>
                            <ActionButtons inBox footerOnMobile>
                              <DeleteWebhookConfiguration
                                service="outOfStock"
                                name={getName(webhook.name)}
                                history={props.history}
                              />
                              <ActionButton type="submit">
                                <i className="fal fa-fw fa-check" /> Save
                              </ActionButton>
                            </ActionButtons>
                            <Fieldset>
                              <Controller
                                as={Input}
                                control={control}
                                type="text"
                                name="name"
                                rules={{
                                  required: "This is a required field",
                                }}
                                defaultValue={webhook.name}
                                errors={errors}
                                label="Name"
                                disabled
                              />
                              <Controller
                                as={Input}
                                control={control}
                                type="text"
                                name="url"
                                rules={{
                                  required: "This is a required field",
                                }}
                                defaultValue={webhook.url}
                                errors={errors}
                                label="Webhook URL"
                              />
                            </Fieldset>

                            <Headers>
                              <h5>Headers</h5>
                              <AddHeaderButton
                                type="button"
                                handleOnClick={() => {
                                  setHeaders((prev) => ({
                                    ...prev,
                                    [`additional-${uuid()}`]: "",
                                  }));
                                }}>
                                <i className="fal fa-plus"></i> Add header
                              </AddHeaderButton>
                              <TableHeader>
                                <div>Key</div>
                                <div>Value</div>
                                <Padding />
                              </TableHeader>
                              {headers &&
                                Object.keys(headers)
                                  .reverse()
                                  .filter((key) => headers[key] !== undefined)
                                  .map((key) => {
                                    return (
                                      <HeaderRow key={key}>
                                        <Controller
                                          as={Input}
                                          control={control}
                                          type="text"
                                          rules={{
                                            required: "This is a required field",
                                          }}
                                          name={`headers.${key}.key`}
                                          defaultValue={key.includes("additional") ? "" : key}
                                          errors={errors}
                                          relativeError
                                        />
                                        <Controller
                                          as={Input}
                                          control={control}
                                          type="text"
                                          rules={{
                                            required: "This is a required field",
                                          }}
                                          name={`headers.${key}.value`}
                                          defaultValue={headers[key]}
                                          errors={errors}
                                          relativeError
                                        />
                                        <IconButton
                                          handleOnClick={() => {
                                            setHeaders((prev) => ({
                                              ...prev,
                                              [key]: undefined,
                                            }));
                                          }}>
                                          <i className="fal fa-fw fa-trash-alt" />
                                        </IconButton>
                                      </HeaderRow>
                                    );
                                  })}
                            </Headers>
                          </Webhook>
                        </Box>
                      </GridItem>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};

import React, { useState } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import getFullCountryName from "helpers/getFullCountryName";
import Button from "components/Ui/Button";
import TableHeader from "components/Table/Header";
import Stores from "components/Table/Rows";
import Row from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import TotalItems from "components/Table/Toolbar/TotalItems";
import Loader from "components/Ui/Loader";
import FlagIcon from "components/Ui/FlagIcon";
import { MEDIA_MIN_X_LARGE } from "variables/mediaQueries";

const Header = styled(TableHeader)`
  justify-content: flex-start;
`;

const StoreToolbar = styled(Toolbar)`
  justify-content: flex-end;
`;

const Store = styled(Row)`
  width: 100% !important;
  min-height: 6rem;
`;

const CountryCode = styled(Column)`
  width: 40%;
  ${MEDIA_MIN_X_LARGE} {
    width: 30%;
  }
`;

const CurrencyUnit = styled(Column)`
  width: 30%;
  ${MEDIA_MIN_X_LARGE} {
    width: 16%;
  }
`;

const Tax = styled(Column)`
  width: 30%;
  ${MEDIA_MIN_X_LARGE} {
    width: 10%;
  }
`;

const Date = styled(Column)`
  width: 19%;
  display: none;
  ${MEDIA_MIN_X_LARGE} {
    display: flex;
  }
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};
`;

export default ({ loading, history, stores, storesTotalHits }) => {
  const [currentSort, setCurrentSort] = useState("down");
  const [sortField, setSortField] = useState("lastUpdated");

  const handleClick = (store) => {
    history.push({
      pathname: `/admin/store/${store.countryCode}`,
      state: store,
    });
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";
    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField]) || sortField === "active") {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  return (
    <>
      <StoreToolbar>
        <TotalItems>
          Total stores: <span>{storesTotalHits || "-"}</span>
        </TotalItems>
      </StoreToolbar>
      <Header>
        <CountryCode onClick={() => onSortChange("countryCode")}>
          <span>Store </span>
          <SortButton>
            <i className={`fas fa-${getSortType("countryCode", currentSort)}`} />
          </SortButton>
        </CountryCode>
        <CurrencyUnit onClick={() => onSortChange("currencyUnit")}>
          <span>Currency</span>
          <SortButton>
            <i className={`fas fa-${getSortType("currencyUnit", currentSort)}`} />
          </SortButton>
        </CurrencyUnit>
        <Tax onClick={() => onSortChange("tax")}>
          <span>Tax </span>
          <SortButton>
            <i className={`fas fa-${getSortType("tax", currentSort)}`} />
          </SortButton>
        </Tax>
        <Date onClick={() => onSortChange("lastUpdated")}>
          <span>Last updated </span>
          <SortButton>
            <i className={`fas fa-${getSortType("lastUpdated", currentSort)}`} />
          </SortButton>
        </Date>
        <Date onClick={() => onSortChange("created")}>
          <span>Created</span>
          <SortButton>
            <i className={`fas fa-${getSortType("created", currentSort)}`} />
          </SortButton>
        </Date>
      </Header>
      <Stores>
        {loading && <Loader />}
        {stores && !loading && (
          <>
            {[...stores].sort(sortTypes[currentSort].fn).map((store) => {
              return (
                <Store key={uuid()} onClick={() => handleClick(store)}>
                  <CountryCode>
                    <FlagIcon countryCode={store.countryCode} />
                    <strong>{getFullCountryName(store.countryCode)}</strong>
                  </CountryCode>
                  <CurrencyUnit>{store.currencyUnit}</CurrencyUnit>
                  <Tax>{`${store.tax} %`}</Tax>
                  <Date>
                    <Moment format="YYYY-MM-DD HH:mm">{store.lastUpdated}</Moment>
                  </Date>
                  <Date>
                    <Moment format="YYYY-MM-DD HH:mm">{store.created}</Moment>
                  </Date>
                </Store>
              );
            })}
          </>
        )}
      </Stores>
    </>
  );
};

import { useQuery } from "@apollo/client";
import SEARCH_ACTIVE_DISCOUNT_CODES from "graphql/Discount/DiscountCode/SearchActiveDiscountCodes";
import esb from "elastic-builder";
import moment from "moment/min/moment-with-locales";

export default () => {
  const now = moment().format("YYYY-MM-DD");
  const query = new esb.requestBodySearch()
    .trackTotalHits(true)
    .query(
      esb.queryStringQuery(
        `!(archived:1) AND !(type:VOUCHER) AND !(counter = usageLimit) AND (startDate:<=${now}) AND (endDate:>=${now})`
      )
    )
    .size(1);

  const { data } = useQuery(SEARCH_ACTIVE_DISCOUNT_CODES, {
    variables: {
      query: JSON.stringify(query),
    },
  });

  return data?.searchDiscounts?.totalHits;
};

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";

import SEARCH_PRODUCTS_INPUT from "graphql/Product/SearchProductsInput";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import DiscountProductsTable from "components/Discount/DiscountProductsTable";
import SearchProducts from "../../views/Products/SearchProducts";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const ProductsBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const PRODUCTS_PER_PAGE = 25;

export default ({
  selectedProducts,
  setSelectedProducts,
  exclude,
  selectedCategories,
  selectVariants = false,
  labelOverrides = {},
  padding,
}) => {
  const [esbQuery, setEsbQuery] = useState();
  const [searchFilters, setSearchFilters] = useState([]);

  useEffect(() => {
    const activeProductsFilter = esb.queryStringQuery("type:product AND !(archived:1)");

    const partOfQuery = esb
      .boolQuery()
      .should(
        esb
          .termsQuery("id.keyword", selectedProducts?.filterProducts ?? selectedProducts.products)
          .boost("4")
      );

    const allProductsBoosted = exclude
      ? new esb.requestBodySearch()
          .query(
            partOfQuery.filter([
              ...searchFilters,
              activeProductsFilter,
              esb.termsQuery(
                "category.keyword",
                selectedCategories.map((category) => category.value)
              ),
            ])
          )
          .sorts([esb.sort("_score", "desc"), esb.sort("lastUpdated", "desc")])
          .size(PRODUCTS_PER_PAGE)
          .from(0)
      : new esb.requestBodySearch()
          .query(partOfQuery.filter([activeProductsFilter, ...searchFilters]))
          .sorts([esb.sort("_score", "desc"), esb.sort("lastUpdated", "desc")])
          .size(PRODUCTS_PER_PAGE)
          .from(0);

    setEsbQuery(allProductsBoosted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, exclude, searchFilters]);

  if (!esbQuery) return null;
  return (
    <GridItem columns="12" padding={padding}>
      <ProductsBox
        preHeading={labelOverrides.preHeading ?? "Discount code"}
        heading={labelOverrides.heading ?? "Add products"}
        subHeading={
          labelOverrides.subHeading ?? "Add all products that are valid for discount code"
        }>
        <SearchProducts setSearchFilters={setSearchFilters} />
        <Query
          query={SEARCH_PRODUCTS_INPUT}
          variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
          fetchPolicy={"cache-first"}>
          {({ loading, error, data, fetchMore }) => {
            if (error)
              return (
                <ErrorMessage>
                  An error occurred when loading data, please contact support
                </ErrorMessage>
              );

            return (
              <DiscountProductsTable
                data={data}
                loading={loading}
                productsPerPage={PRODUCTS_PER_PAGE}
                fetchMore={fetchMore}
                esbQuery={esbQuery}
                setEsbQuery={setEsbQuery}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                selectVariants={selectVariants}
              />
            );
          }}
        </Query>
      </ProductsBox>
    </GridItem>
  );
};

import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Input from "components/Ui/Input";
import Button from "components/Ui/Button";

const SearchForm = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 54rem;
  margin: 2rem 0;

  ${MEDIA_MIN_LARGE} {
    position: absolute;
    top: 1rem;
    right: 3rem;
  }
`;

const SearchInput = styled(Input)`
  width: 100%;
  margin-right: 1rem;
  height: 5rem;
  border: none;
  border-bottom: 0.2rem solid ${(p) => p.theme.colors.primary};

  ${MEDIA_MIN_LARGE} {
    height: 5rem;
  }
`;

const SearchButton = styled(Button)`
  max-width: 8rem;
  height: 5rem;

  ${MEDIA_MIN_LARGE} {
    height: 5rem;
    max-width: 10rem;
  }
`;

export default ({
  inputError,
  setInputError,
  setSearchInput,
  searchFunc,
  placeholder,
  defaultValue,
  ...props
}) => (
  <SearchForm {...props}>
    <SearchInput
      autoComplete="off"
      icon="search"
      error={inputError}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={(e) => {
        if (e.target.value.length > 0) setInputError(false);
        setSearchInput(e.target.value);
      }}
      onKeyDown={(e) => e.key === "Enter" && searchFunc(e)}
    />
    <SearchButton isLoading={false} onClick={searchFunc}>
      Search
    </SearchButton>
  </SearchForm>
);

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import { useQuery } from "@apollo/client";
import esb from "elastic-builder";
import SEARCH_DISCOUNT_CODES from "graphql/Discount/DiscountCode/SearchDiscountCodes";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import GET_DISCOUNT_CODE from "graphql/Discount/DiscountCode/GetDiscountCode";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import SearchDiscountCodes from "./SearchDiscountCodes";
import DiscountCodesTable from "./DiscountCodesTable";
import Sidebar from "components/Discount/Sidebar";
import useTabState from "../../../helpers/useTabstate";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import DiscountCode from "./DiscountCode";

const DiscountsBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const DISCOUNT_CODES_PER_PAGE = 25;
const ALL_DISCOUNT_CODES = new esb.requestBodySearch()
  .trackTotalHits(true)
  .query(esb.queryStringQuery("!(archived:1) OR !(type:VOUCHER)"))
  .sort(esb.sort("lastUpdated", "desc"))
  .size(DISCOUNT_CODES_PER_PAGE)
  .from(0);

export default (props) => {
  const [esbQuery, setEsbQuery] = useState(ALL_DISCOUNT_CODES);
  const [urlId, setUrlId] = useState();
  const [tabs, setTabs] = useTabState([], "discountcode-tabs");
  const [activeTab, setActiveTab] = useState("table");
  const { data } = useQuery(GET_DISCOUNT_CODE, {
    variables: { id: urlId },
    skip: !urlId,
  });

  const closeTab = (id) => {
    setTabs(tabs.filter((item) => item.id !== id));
    if (tabs.length === 1) return;
    const tabIndex = tabs.findIndex((t) => t.id === id);
    setActiveTab(tabIndex === tabs.length - 1 ? tabs[tabIndex - 1].id : tabs[tabIndex + 1].id);
  };

  useEffect(() => {
    tabs.length > 0 && setActiveTab(tabs[tabs.length - 1].id);
  }, [tabs]);

  useEffect(() => {
    if (props.location.state) {
      const { id, reference } = props.location.state;
      !tabs.find((item) => item.id === id) && setTabs((prev) => [...prev, { id, reference }]);
      setActiveTab(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state, tabs]);

  useEffect(() => {
    const id = window.location.pathname.split("/")[3];
    if (id) {
      setUrlId(id);
      if (data) {
        !tabs.find((item) => item.id === id) &&
          setTabs((prev) => [...prev, { id, reference: data.getDiscount.code }]);
        setActiveTab(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/discount-codes", "Discount codes"],
        ]}
      />
      <Header heading="Discount codes" />

      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <Tabs>
              <Tab
                onClick={() => setActiveTab("table")}
                active={tabs?.length === 0 || activeTab === "table"}>
                Codes
              </Tab>
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  tab={tab}
                  onClick={() => setActiveTab(tab.id)}
                  active={tab.id === activeTab}
                  closeTab={closeTab}>
                  {tab.reference}
                </Tab>
              ))}
            </Tabs>
            {tabs.length === 0 || activeTab === "table" ? (
              <GridContainer>
                <DiscountsBox preHeading="Overview" heading="Discount codes">
                  <SearchDiscountCodes
                    allDiscounts={ALL_DISCOUNT_CODES}
                    esbQuery={esbQuery}
                    setEsbQuery={setEsbQuery}
                  />
                  <Query
                    query={SEARCH_DISCOUNT_CODES}
                    fetchPolicy="network-only"
                    variables={{ query: JSON.stringify(esbQuery.toJSON()) }}>
                    {({ loading, error, data, fetchMore, subscribeToMore, refetch }) => {
                      if (error)
                        return (
                          <ErrorMessage inBox>
                            An error occurred when loading data, please contact support
                          </ErrorMessage>
                        );

                      return (
                        <DiscountCodesTable
                          discountData={data}
                          discountLoading={loading}
                          discountsPerPage={DISCOUNT_CODES_PER_PAGE}
                          history={props.history}
                          fetchMore={fetchMore}
                          subscribeToMore={subscribeToMore}
                          esbQuery={esbQuery}
                          refetch={refetch}
                          setTabs={setTabs}
                          tabs={tabs}
                          setActiveTab={setActiveTab}
                        />
                      );
                    }}
                  </Query>
                </DiscountsBox>
              </GridContainer>
            ) : (
              tabs.map(
                (tab) =>
                  activeTab === tab.id && (
                    <DiscountCode key={tab.id} id={tab.id} setTabs={setTabs} closeTab={closeTab} />
                  )
              )
            )}
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

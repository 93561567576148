import React, { useState } from "react";
import esb from "elastic-builder";
import SearchForm from "components/Table/Search/SearchForm";
import styled from "styled-components";

const StyledSearchForm = styled(SearchForm)`
  position: static;
  max-width: none;
`;

export default ({ setEsbQuery, group }) => {
  const [searchInput, setSearchInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const searchVouchers = (evt) => {
    evt.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    setEsbQuery(
      new esb.requestBodySearch().query(
        esb
          .queryStringQuery(
            searchInput
              .split(" ")
              .join("* ")
              .concat("*")
              .concat(` AND !(archived:1) AND (type:VOUCHER) AND (group:${group})`)
          )
          .analyzeWildcard()
      )
    );
  };
  return (
    <StyledSearchForm
      placeholder="Voucher code"
      inputError={inputError}
      setInputError={setInputError}
      setSearchInput={setSearchInput}
      searchFunc={searchVouchers}
    />
  );
};

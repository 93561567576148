import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";

import SEARCH_PRODUCTS from "graphql/Product/SearchProducts";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import ProductsTable from "./ProductsTable";
import SearchProducts from "./SearchProducts";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Product/Sidebar";

const ProductsBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const PRODUCTS_PER_PAGE = 25;

const Products = (props) => {
  const [esbQuery, setEsbQuery] = useState();
  const [searchFilters, setSearchFilters] = useState([]);

  useEffect(() => {
    const sort = esb.sort("lastUpdated", "desc");
    const activeProductsFilter = esb.queryStringQuery("type:product AND !(archived:1)");
    const activeProductVariantFilter = esb.queryStringQuery(
      "type:product OR type:productVariant AND !(archived:1)"
    );
    const boolQuery = esb
      .boolQuery()
      .filter([
        ...searchFilters,
        searchFilters.length > 0 ? activeProductVariantFilter : activeProductsFilter,
      ]);
    setEsbQuery(
      new esb.requestBodySearch().query(boolQuery).sort(sort).size(PRODUCTS_PER_PAGE).from(0)
    );
  }, [searchFilters]);

  if (!esbQuery) return null;
  return (
    <>
      <Breadcrumbs slugs={[["admin/products", "Products"]]} />
      <Header heading="Products"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <ProductsBox preHeading="Overview" heading="Products">
              <SearchProducts setSearchFilters={setSearchFilters} />
              <Query
                query={SEARCH_PRODUCTS}
                variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
                fetchPolicy={"cache-first"}>
                {({ loading, error, data, fetchMore }) => {
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );

                  const products = data?.searchProducts?.products?.map(
                    (p) => p.relatedProduct || p
                  );
                  const uniqueProducts =
                    products &&
                    Array.from(new Set(products.map((a) => a.id)))?.map((id) => {
                      return products.find((a) => a.id === id);
                    });

                  return (
                    <ProductsTable
                      productData={uniqueProducts}
                      totalHits={
                        searchFilters.length > 0
                          ? uniqueProducts?.length
                          : data?.searchProducts?.totalHits
                      }
                      hideTotalVariants={searchFilters.length > 0}
                      loading={loading}
                      productsPerPage={PRODUCTS_PER_PAGE}
                      fetchMore={fetchMore}
                      history={props.history}
                      esbQuery={esbQuery}
                    />
                  );
                }}
              </Query>
            </ProductsBox>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return Products(props);
};

import React, { useEffect, useState } from "react";
import { Query } from "@apollo/client/react/components";
import Box from "components/Dashboard/Box";
import styled from "styled-components/macro";
import ChartistGraph from "react-chartist";
import ORDER_AGGREGATE from "graphql/Order/OrderAggregate";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import moment from "moment-timezone";
import esb from "elastic-builder";

const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

export default ({ selectedStores }) => {
  if (!selectedStores) return <Loader />;
  const [dailyOrders, setDailyOrders] = useState();

  const yesterday = moment().subtract(1, "days").startOf("day");
  const today = moment().endOf("day");

  useEffect(() => {
    setDailyOrders(
      new esb.requestBodySearch()
        .query(
          esb
            .boolQuery()
            .must([
              esb.matchQuery("statusLog.status", "success"),
              esb.termsQuery("store.keyword", selectedStores),
              esb
                .rangeQuery("created")
                .gte(yesterday.format("YYYY-MM-DD"))
                .format("yyyy-MM-dd")
                .timeZone(moment.tz.guess()),
            ])
        )
        .agg(esb.dateHistogramAggregation("created", "created", "1d"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStores]);

  if (!dailyOrders) return null;
  return (
    <Query query={ORDER_AGGREGATE} variables={{ query: JSON.stringify(dailyOrders.toJSON()) }}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error)
          return (
            <ErrorMessage>An error occurred when loading data, please contact support</ErrorMessage>
          );

        const buckets = JSON.parse(data.orderAggregates.aggregations).created.buckets;
        const prevPeriodAmount =
          buckets.find(
            (b) => moment(b.key_as_string).format("YYYY-MM-DD") === yesterday.format("YYYY-MM-DD")
          )?.doc_count ?? 0;

        const currentPeriodAmount =
          buckets.find(
            (b) => moment(b.key_as_string).format("YYYY-MM-DD") === today.format("YYYY-MM-DD")
          )?.doc_count ?? 0;

        const dailySalesChart = {
          data: {
            series: [currentPeriodAmount, prevPeriodAmount],
          },
          options: {
            donut: true,
            donutWidth: 15,
            donutSolid: true,
            startAngle: 0,
            showLabel: false,
          },
        };

        return (
          <Box
            preHeading="Daily orders"
            heading={`# ${currentPeriodAmount}`}
            headingIcon="shopping-cart"
            primaryLabel="Today"
            secondaryLabel="Yesterday"
            currentPeriodSum={currentPeriodAmount}
            prevPeriodSum={prevPeriodAmount}>
            <Container>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={dailySalesChart.data}
                type="Pie"
                options={dailySalesChart.options}
              />
            </Container>
          </Box>
        );
      }}
    </Query>
  );
};

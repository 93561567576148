import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import ALL_STORES from "graphql/Store/AllStores";
import getFullCountryName from "helpers/getFullCountryName";
import FlagIcon from "components/Ui/FlagIcon";
import { ErrorMessage } from "@hookform/error-message";
import { alerts } from "variables/colors";
import { continents, countries } from "countries-list";
import Input from "components/Ui/Input";

const Container = styled.div`
  font-size: 1.3rem;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StoreSelector = styled.div`
  font-size: 1.3rem;
  margin: 0;
  margin-bottom: ${(p) => (p.errors ? "1rem" : "3rem")};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(p) => (p.expanded ? "800" : "-1")};
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.8rem;
  display: block;
  font-weight: 700;
`;

const Summary = styled.div`
  border-radius: 0.3rem;
  border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  background: ${(p) => p.theme.colors.inputBackgroundColor};
  height: ${(p) => (p.small ? "4rem" : "5rem")};
  margin-right: 1rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  color: black;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const SearchInput = styled(Input)`
  border-radius: 0;
  margin-bottom: 0.1rem;
  height: 4rem;
`;

const Flag = styled(FlagIcon)`
  width: 1.6rem;
  margin-left: 1rem;
`;

const Icon = styled.i`
  font-size: ${(p) => (p.small ? "1.8rem" : "2rem")};
  margin-right: 1rem;
  position: relative;
  top: 0.25rem;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(Icon)`
  animation: ${rotate} 2s linear infinite;
`;

const ErrorText = styled.span`
  width: 100%;
  color: ${alerts.error.highlight};
  position: relative;
  letter-spacing: 0;
  bottom: 0;
`;

const DropDown = styled.div`
  position: absolute;
  z-index: 1000;
  display: ${(p) => (p.expanded ? "flex" : "none")};
  max-height: 45rem;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.black};
  overflow: scroll;
  width: calc(100% - 1rem);
  top: ${(p) => p.dropDownTop};
`;

const Checkbox = styled.input`
  margin-right: 1rem;
`;

const ContinentWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: 1rem 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  background-color: ${(p) => (p.expanded ? p.theme.colors.secondary : p.theme.colors.black)};
  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.secondary};
  }
  i {
    margin-left: 1rem;
  }
`;

const Continent = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const CountryWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: 1rem 2rem;
  &:hover {
    cursor: pointer;
  }
  span {
    display: flex;
  }
  i {
    margin-left: 1rem;
  }
`;

const Country = styled.span`
  margin-left: 0.2rem;
`;

export default ({
  selectedStores,
  setSelectedStores,
  label,
  errors,
  setValue,
  dropDownTop = "8rem",
  small,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const [groupedStores, setGroupedStores] = useState();
  const [selectAllStores, setSelectAllStores] = useState(false);
  const [filteredStores, setFilteredStores] = useState([]);

  const updateStores = (code, continent) => {
    let updatedGroupedStores = [];

    updatedGroupedStores = continent
      ? groupedStores.map((c) =>
          c.continentCode === code
            ? {
                ...c,
                checked: !c.checked,
                stores: c.stores.map((s) => ({ ...s, checked: !c.checked })),
              }
            : c
        )
      : groupedStores.map((c) => ({
          ...c,
          stores: c.stores.map((s) => (s.value === code ? { ...s, checked: !s.checked } : s)),
        }));

    setGroupedStores(updatedGroupedStores);
    setSelectedStores(
      updatedGroupedStores
        .map((c) => c.stores.filter((s) => s.checked ?? s.value))
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
    );
  };

  const handleOutsideClick = () => {
    setExpanded(!expanded);
    setFilteredStores([]);
  };

  const handleSearch = (searchInput) => {
    if (searchInput === "") return setFilteredStores([]);
    const result = [];
    groupedStores.map((c) =>
      c.stores.map(
        (s) => s.label.toLowerCase().includes(searchInput.toLowerCase()) && result.push(s)
      )
    );
    setFilteredStores(result);
  };

  useEffect(() => {
    const allGroupStores = groupedStores
      ? groupedStores.map((c) => ({
          ...c,
          checked: selectAllStores,
          stores: c.stores.map((s) => ({ ...s, checked: selectAllStores })),
        }))
      : null;

    if (allGroupStores) {
      setGroupedStores(allGroupStores);
      setSelectedStores(
        allGroupStores
          .map((c) => c.stores.filter((s) => s.checked ?? s.value))
          .reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllStores]);

  useEffect(() => {
    if (setValue) setValue("stores", selectedStores);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStores]);

  const getContinentGlobe = (code) => {
    switch (code) {
      case "AF":
        return "fa-earth-africa";
      case "AS":
        return "fa-earth-asia";
      case "EU":
        return "fa-earth-europe";
      case "OC":
        return "fa-earth-oceania";
      default:
        return "fa-earth-americas";
    }
  };

  return (
    <>
      <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}>
        {({ data }) => {
          if (!data)
            return (
              <StoreSelector>
                {label && <Label>{label}</Label>}
                <Summary>
                  <span>
                    <LoadingIcon className="fa-light fa-spinner-third" />
                    Loading stores
                  </span>
                </Summary>
              </StoreSelector>
            );
          const stores = data.allStores.stores
            .slice()
            .sort((a, b) => a.countryCode.localeCompare(b.countryCode))
            .map((store) => ({
              value: store.countryCode,
              label: getFullCountryName(store.countryCode),
              currencyUnit: store.currencyUnit,
              tax: store.tax,
              languageCode: store.languageCode,
              continent: countries[store.countryCode]?.continent ?? store.countryCode,
              checked: selectedStores?.find((s) => s.value === store.countryCode) ? true : false,
            }));

          if (!groupedStores)
            setGroupedStores(
              Object.keys(continents).map((continent) => ({
                continentCode: continent,
                continentName: continents[continent],
                stores: stores.filter((store) => store.continent === continent),
                expanded: false,
                checked: false,
                numberOfCheckedStores: 0,
              }))
            );

          return (
            <Container>
              <StoreSelector errors={errors?.stores}>
                {label && <Label>{label}</Label>}
                <Summary onClick={() => setExpanded(!expanded)} small={small}>
                  <span>
                    <Icon className="fa-light fa-earth-americas" />
                    {selectedStores?.length > 0
                      ? `${selectedStores.length} stores selected`
                      : "Select stores"}
                  </span>
                  {expanded ? (
                    <i className="far fa-fw fa-chevron-up"></i>
                  ) : (
                    <i className="far fa-fw fa-chevron-down"></i>
                  )}
                </Summary>
                <Backdrop expanded={expanded} onClick={handleOutsideClick} />
                <DropDown expanded={expanded} dropDownTop={dropDownTop}>
                  {expanded && (
                    <>
                      <SearchInput
                        type="text"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      {filteredStores.length < 1 && (
                        <ContinentWrapper>
                          <Checkbox
                            checked={selectAllStores}
                            type="checkbox"
                            tabIndex="-1"
                            onChange={() => setSelectAllStores(!selectAllStores)}
                          />
                          <span onClick={() => setSelectAllStores(!selectAllStores)}>
                            <Icon className={`fa-duotone fa-circle-check`} small />
                            <span>Select all</span>
                          </span>
                        </ContinentWrapper>
                      )}
                    </>
                  )}
                  {expanded &&
                    (filteredStores.length > 0
                      ? filteredStores.map((store) => (
                          <CountryWrapper key={store.value}>
                            <Checkbox
                              onChange={() => updateStores(store.value)}
                              checked={groupedStores.find(
                                (continent) =>
                                  continent.stores.find(
                                    (continentStore) => continentStore.value === store.value
                                  )?.checked
                              )}
                              type="checkbox"
                              tabIndex="-1"
                            />
                            <Country onClick={() => updateStores(store.value)}>
                              <Flag countryCode={store.value} />
                              <span>{store.label}</span>
                            </Country>
                          </CountryWrapper>
                        ))
                      : groupedStores.map((continent) => {
                          return (
                            <div key={continent.continentCode}>
                              {continent?.stores?.length > 0 && (
                                <div>
                                  <ContinentWrapper expanded={continent.expanded}>
                                    <Checkbox
                                      checked={
                                        continent.stores.filter((s) => s.checked)?.length > 0
                                      }
                                      onChange={() =>
                                        updateStores(continent.continentCode, continent)
                                      }
                                      type="checkbox"
                                      tabIndex="-1"
                                    />
                                    <Continent
                                      onClick={() =>
                                        setGroupedStores(
                                          groupedStores.map((c) =>
                                            c.continentCode === continent.continentCode
                                              ? { ...c, expanded: !c.expanded }
                                              : c
                                          )
                                        )
                                      }>
                                      <span>
                                        <Icon
                                          small={continent.continentCode === "EU"}
                                          className={`fa-duotone ${getContinentGlobe(
                                            continent.continentCode
                                          )}`}
                                        />
                                        <span>{continent.continentName}</span>
                                        <span>{` (${
                                          continent.stores.filter((s) => s.checked)?.length
                                        }/${continent?.stores?.length})`}</span>
                                      </span>

                                      {continent.expanded ? (
                                        <i className="far fa-fw fa-chevron-up"></i>
                                      ) : (
                                        <i className="far fa-fw fa-chevron-down"></i>
                                      )}
                                    </Continent>
                                  </ContinentWrapper>
                                  {continent.expanded &&
                                    continent.stores.map((store) => (
                                      <CountryWrapper key={store.value}>
                                        <Checkbox
                                          onChange={() => updateStores(store.value)}
                                          checked={store.checked}
                                          type="checkbox"
                                          tabIndex="-1"
                                        />
                                        <Country onClick={() => updateStores(store.value)}>
                                          <Flag countryCode={store.value} />
                                          <span>{store.label}</span>
                                        </Country>
                                      </CountryWrapper>
                                    ))}
                                </div>
                              )}
                            </div>
                          );
                        }))}
                </DropDown>
              </StoreSelector>
              {errors && (
                <ErrorText>
                  <ErrorMessage errors={errors} name="stores" />
                </ErrorText>
              )}
            </Container>
          );
        }}
      </Query>
    </>
  );
};

import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";

import SEARCH_SHIPPING_OPTIONS from "graphql/Product/SearchShippingOptions";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import ShippingOptionsTable from "./ShippingOptionsTable";
import Box from "components/Content/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Shipping/Sidebar";

const ShippingOptionsBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const SHIPPING_OPTIONS_PER_PAGE = 20;

const ALL_SHIPPING_OPTIONS = new esb.requestBodySearch()
  .sort(esb.sort("lastUpdated", "desc"))
  .query(esb.queryStringQuery("type:shippingOption AND !(archived:1)"))
  .size(SHIPPING_OPTIONS_PER_PAGE)
  .from(0);

const ShippingOptions = (props) => {
  const [esbQuery] = useState(ALL_SHIPPING_OPTIONS);
  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/configurations", "Configurations"],
          ["admin/shipping-options", "Shipping options"],
        ]}
      />
      <Header heading="Shipping options"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <ShippingOptionsBox preHeading="Overview" heading="Shipping options">
              <Query
                query={SEARCH_SHIPPING_OPTIONS}
                variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
                fetchPolicy={"cache-first"}>
                {({ loading, error, data, fetchMore }) => {
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );

                  return (
                    <ShippingOptionsTable
                      data={data}
                      loading={loading}
                      optionsPerPage={SHIPPING_OPTIONS_PER_PAGE}
                      fetchMore={fetchMore}
                      history={props.history}
                      esbQuery={esbQuery}
                    />
                  );
                }}
              </Query>
            </ShippingOptionsBox>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return ShippingOptions(props);
};

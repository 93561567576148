import React from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";
import uuid from "react-uuid";
import Tooltip from "components/Ui/Tooltip";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import TableHeader from "components/Table/Header";
import PRODUCT_AGGREGATES from "graphql/Product/ProductAggregates";
import FilterRule from "./FilterRule";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Rows from "components/Table/Rows";
import Row from "components/Table/Row";
import Loader from "components/Ui/Loader";

const RuleRow = styled(Row)`
  padding: 1.6rem 0rem 1.4rem;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 6rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }

  h5 {
    margin-bottom: 0;
    border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    padding-top: 3.2rem;
    width: 100%;
  }
`;

const RulesHeader = styled(TableHeader)`
  > div {
    cursor: default;
    width: 35%;
  }
`;

const Rules = styled.div`
  width: 100%;
  position: relative;

  & + & {
    margin-top: 5rem;
  }

  h5 {
    border-top: 0;
    padding: 0 0 3rem;
  }
`;

const AddRowButton = styled(ActionButtonSecondary)`
width: 100%;
`;

const ALL_FILTER_KEYS = esb
  .requestBodySearch()
  .agg(
    esb
      .nestedAggregation("filterKeys", "filterAttributes")
      .agg(esb.termsAggregation("filterKeys", "filterAttributes.key").size(100))
  )
  .size(100);

const MODE_OPTIONS = [
  {
    label: "Include",
    value: "INCLUDE",
  },
  {
    label: "Exclude",
    value: "EXCLUDE",
  },
];

export default ({ control, filterRules, setFilterRules, title="Rules"}) => {
  return (
    <Container>
      <Query
        query={PRODUCT_AGGREGATES}
        variables={{ query: JSON.stringify(ALL_FILTER_KEYS.toJSON()) }}
        fetchPolicy="cache-first">
        {({ error, data, loading }) => {
          if (error)
            return <ErrorMessage>An error occurred getting data, contact support</ErrorMessage>;
          if (loading) return <Loader />;

          let filterKeys = [];
          if (data)
            filterKeys = JSON.parse(
              data?.productAggregates.aggregations
            ).filterKeys.filterKeys.buckets.map((bucket) => ({
              label: bucket.key,
              value: bucket.key,
            }));

          return (
            <>
              <Tooltip />
              <Rules>
                <h5>{title}</h5>
             
                <RulesHeader>
                  <div>
                    Rule mode *&nbsp;
                    <i
                      className="fal fa-fw fa-info-circle"
                      data-tip="Decides whether the filter will include or exclude products matching the filter key and value(s)"
                    />
                  </div>
                  <div>
                    Key *&nbsp;
                    <i
                      className="fal fa-fw fa-info-circle"
                      data-tip="The attribute key to match on"
                    />
                  </div>
                  <div>
                    Value(s) *&nbsp;
                    <i
                      className="fal fa-fw fa-info-circle"
                      data-tip="The attribute values to match on"
                    />
                  </div>
                </RulesHeader>
                <Rows>
                  {filterRules.map((filterRule) => (
                    <RuleRow key={filterRule.id}>
                      <FilterRule
                        modes={MODE_OPTIONS}
                        control={control}
                        filterKeys={filterKeys}
                        filterRule={filterRule}
                        filterRules={filterRules}
                        setFilterRules={setFilterRules}
                      />
                    </RuleRow>
                  ))}
                </Rows>
                <AddRowButton
                  type="button"
                  handleOnClick={() => {
                    setFilterRules([
                      ...filterRules,
                      { mode: "INCLUDE", key: "", values: [], id: uuid() },
                    ]);
                  }}>
                  <i className="fal fa-plus" /> Add
                </AddRowButton>
              </Rules>
            </>
          );
        }}
      </Query>
    </Container>
  );
};

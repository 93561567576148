import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import styled, { keyframes } from "styled-components/macro";
import Dialog from "components/Dialog/Dialog";
import Box from "components/Content/Box";
import FilterButton from "components/Ui/FilterButton";
import { json2csv } from "json-2-csv";
import moment from "moment/min/moment-with-locales";
import { useNotification } from "context/NotificationContext";
import Checkbox from "components/Ui/Checkbox";
import esb from "elastic-builder";
import Tooltip from "components/Ui/Tooltip";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;
  pointer-events: all;
  z-index: 10000;
  opacity: 1;
`;

const Container = styled(Box)`
  max-width: 40rem;
  margin: 0 3rem;
`;

const Header = styled.h4`
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
`;

const Loader = styled.div`
  width: 500px;
  margin: 0 auto;
  border: 4px solid transparent;
  border-radius: 0.6rem;
  position: relative;
  padding: 4px;

  &:before {
    content: "";
    border: 1px solid rgb(95, 113, 205);
    border-radius: 0.6rem;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
`;

const ExportDialog = styled(Dialog)`
  overflow: scroll;
  .dialogContainer {
    max-width: none;
    width: 50%;
  }
  p {
    width: 100%;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem;
  width: 25rem;
  input {
    margin-right: 1rem;
  }
`;

const borealisBar = keyframes`
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
`;

const LoaderBar = styled.div`
  position: absolute;
  border-radius: 0.6rem;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(95, 113, 205) 0%, rgb(55, 77, 192) 100%);
  width: 0;
  animation: ${borealisBar} 2s linear infinite;
`;

const KlaviyoToolTip = styled.i`
  margin-left: 1rem;
`;

function Exporter({ query, esbQuery, onComplete, onError, size }) {
  const variables = { query: JSON.stringify(esbQuery.toJSON()) };
  const { loading, error, data, fetchMore } = useQuery(query, {
    variables,
  });

  useEffect(() => {
    const fetchAllData = async () => {
      if (loading) return;
      if (error) {
        console.error(error);
        onError(error.message);
        return;
      }

      let discounts = [...data.searchDiscounts.discounts];
      const loops = Math.trunc(size / BATCH_SIZE);
      for (let i = 0; i < loops; i++) {
        const from = (i + 1) * BATCH_SIZE;
        const nextBatch = await fetchMore({
          variables: {
            query: JSON.stringify(esbQuery.size(BATCH_SIZE).from(from).toJSON()),
          },
        });
        discounts = discounts.concat([...nextBatch.data.searchDiscounts.discounts]);
      }

      onComplete(discounts);
    };

    data && fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data, onComplete, onError]);

  return (
    <Backdrop>
      <Container>
        <Header>Generating export</Header>
        <Loader>
          <LoaderBar />
        </Loader>
      </Container>
    </Backdrop>
  );
}

const BATCH_SIZE = 500;

export default function VoucherExport({ query, esbQuery, group, size }) {
  const { setNotification } = useNotification();
  const [dialog, showDialog] = useState(false);
  const [klaviyoFormat, setKlaviyoFormat] = useState(false);
  const [exporting, setExporting] = useState(false);

  function onCancel() {
    if (exporting) return;
    showDialog(false);
  }

  function onExport(event) {
    event.stopPropagation();
    if (klaviyoFormat) {
      esbQuery.must(esb.queryStringQuery(`counter:0 AND usageLimit:1`));
    }
    showDialog(false);
    setExporting(true);
  }

  function onError(errorMessage) {
    setExporting(false);
    setNotification({
      status: "error",
      message: `Could not fetch vouchers: ${errorMessage}`,
    });
  }

  function onComplete(vouchers) {
    const formattedVouchers = klaviyoFormat
      ? vouchers.map((v) => ({
          Coupon: v.code,
        }))
      : vouchers.map((v) => ({
          code: v.code,
          group: group,
          ...(v.discountInPercentage && { discountInPercentage: v.discountInPercentage }),
          endDate: v.endDate,
          usageCount: v.usageCount,
          usageLimit: v.usageLimit,
        }));

    json2csv(formattedVouchers, (_, csv) => {
      const blob = new Blob([csv], { type: "text/csv" });
      const filename = `vouchers-export-${group}-${moment().format("YYYY-MM-DD-HH_mm")}.csv`;

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const element = document.createElement("a");
        element.setAttribute("href", URL.createObjectURL(blob));
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      setExporting(false);
    });
  }

  return (
    <>
      <FilterButton handleOnClick={() => showDialog(true)}>
        <i className="fal fa-download" /> Export
      </FilterButton>
      <ExportDialog
        header={`Export vouchers`}
        text={`Do you want to export all voucher codes from group ${group} as a CSV?`}
        open={dialog}
        handleClose={onCancel}
        handleOk={onExport}>
        <CheckboxContainer>
          <Tooltip></Tooltip>
          <Checkbox onChange={() => setKlaviyoFormat(!klaviyoFormat)} />
          Klaviyo format
          <KlaviyoToolTip
            className="fal fa-fw fa-info-circle"
            data-tip="Usage limit can max be 1"
          />
        </CheckboxContainer>
      </ExportDialog>
      {exporting && (
        <Exporter
          query={query}
          esbQuery={esb.requestBodySearch().query(esbQuery).from(0).size(BATCH_SIZE)}
          size={size}
          onComplete={onComplete}
          onError={onError}
        />
      )}
    </>
  );
}

import React, { useState } from "react";
import uuid from "react-uuid";
import styled from "styled-components/macro";
import { Mutation } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form";
import ADD_DISCOUNT_CODE from "graphql/Discount/DiscountCode/AddDiscountCode";
import SEARCH_DISCOUNT_CODE from "graphql/Discount/SearchDiscounts";
import { useNotification } from "context/NotificationContext";
import { customAlphabet } from "nanoid";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Input from "components/Ui/InputNew";
import ProgressLoader from "components/Ui/ProgressLoader";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Value from "components/AttributeList/Value";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import Sidebar from "components/Discount/Sidebar";
import DiscountCodeCurrencies from "components/Discount/DiscountCode/DiscountCodeCurrencies";
import Toggle from "components/Ui/Toggle";
import Tooltip from "components/Ui/Tooltip";
import Button from "components/Ui/Button";
import moment from "moment/min/moment-with-locales";
import { useLazyQuery } from "@apollo/client";
import esb from "elastic-builder";
import getEditFreeShipping from "helpers/getEditFreeShipping";
import { formatPrice } from "../../../helpers/money";
import SaveBar from "components/Ui/SaveBar";
import FilterRules from "../DiscountCode/FilterRules";
import FilterProducts from "../DiscountCode/FilterProducts";
import StoreSelector from "components/StoreSelector/StoreSelector";

const TypeButton = styled(Button)`
  text-transform: capitalize;
  background: ${(p) =>
    p.currentType ? (p) => p.theme.colors.primaryGradient : p.theme.colors.greyOpac};
  color: ${(p) => (p.currentType ? p.theme.colors.buttonText : p.theme.colors.white)};
  font-weight: 700;
  border-radius: 0;
  &:hover {
    background: ${(p) =>
      !p.currentType ? p.theme.colors.secondary : (p) => p.theme.colors.primaryGradient};
    filter: ${(p) => (p.currentType ? "brightness(110%)" : "brightness(100%)")};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FixedToggle = styled(Toggle)`
  div {
    background: ${(p) => p.theme.colors.primary};
  }
`;

const RuleAttributes = styled(Attributes)`
  margin: -1.5rem 0 3rem;
`;

const ErrorLabel = styled.p`
  color: red;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  span {
    display: flex;
    align-items: center;
  }
`;

const SelectDates = styled.div`
  width: 100%;

  ${MEDIA_MIN_LARGE} {
    display: flex;
    justify-content: space-between;

    > div {
      margin: 0;
      width: 48%;
    }
  }
`;

const StoreSelect = styled(StoreSelector)`
  width: 100%;
  margin: 0 0 6.2rem;
  .dropdown-heading {
    height: 4.8rem !important;
  }
`;

const uppercaseAlphabet = "ABCDEFGHIJKLMNOPQRSTUVXYZ123456789";

export default ({ history }) => {
  const { setNotification } = useNotification();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [selectedStores, setSelectedStores] = useState([]);
  const [storesMissingLabel, setStoresMissingLabel] = useState(false);
  const [discountFixed, setDiscountFixed] = useState(false);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [voucherGroupLoading, setVoucherGroupLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [filter, setFilter] = useState(false);
  const [freeShipping, setFreeShipping] = useState(false);
  const [combine, setCombine] = useState(false);
  const [filterRules, setFilterRules] = useState([
    { mode: "INCLUDE", key: "", values: [], id: uuid() },
  ]);

  const createVoucherCode = (prefix) => {
    const nanoId = customAlphabet(uppercaseAlphabet, 10);
    return prefix ? `${prefix}${nanoId()}` : nanoId();
  };

  const updateSelectedStores = (stores) => {
    setSelectedStores(stores);
    const currencies = stores.map((store) => store.currencyUnit);
    setAvailableCurrencies([...new Set(currencies)]);
  };

  const voucherGroupCreated = () => {
    setVoucherGroupLoading(false);
    setNotification({
      status: "success",
      message: `Voucher group successfully added`,
    });
    history.push(`/admin/vouchers`);
  };

  const findVoucherGroupQuery = (id) => {
    return esb
      .requestBodySearch()
      .query(
        esb
          .boolQuery()
          .must([esb.termsQuery("group.keyword", id), esb.termsQuery("type.keyword", "VOUCHER")])
      )
      .from(0)
      .size(1);
  };

  const [findVoucherGroup] = useLazyQuery(SEARCH_DISCOUNT_CODE);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/vouchers", "Vouchers"],
          ["admin/add-voucher-group", "Add voucher group"],
        ]}
      />
      <Header heading="Vouchers" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Mutation
            mutation={ADD_DISCOUNT_CODE}
            onError={() => {
              setNotification({
                status: "error",
                message: "An error occurred adding the discount rule, please contact support",
              });
            }}>
            {(addDiscountCode) => {
              const onSubmit = async (data) => {
                let typeAttribute = {};
                if (selectedStores.length < 1) {
                  setStoresMissingLabel(true);
                } else {
                  if (data.price) {
                    const discountInFixedPrice = Object.keys(data.price).map((key) => ({
                      amount: formatPrice(Number(data.price[key].amount), key),
                      currencyUnit: key,
                    }));
                    typeAttribute = { discountInFixedPrice: discountInFixedPrice };
                  } else {
                    typeAttribute = {
                      discountInPercentage: parseInt(data.discountInPercentage),
                    };
                  }

                  if (filter)
                    typeAttribute = {
                      ...typeAttribute,
                      minItems: data.minItems,
                      filterRules: filterRules.map((filterRule) => ({
                        mode: filterRule.mode,
                        key: filterRule.key,
                        values: filterRule.values,
                      })),
                    };

                  setVoucherGroupLoading(true);
                  for (let i = 0; i < data.numberOfVouchers; i++) {
                    const variables = {
                      type: "VOUCHER",
                      group: data.name,
                      usageLimit: parseInt(data.usageLimit),
                      code: createVoucherCode(data?.prefix),
                      startDate: data.startDate,
                      freeShipping: freeShipping,
                      combine: combine,
                      endDate: data.endDate,
                      stores: selectedStores.map((store) => ({
                        countryCode: store.value,
                        languageCode: store.languageCode,
                      })),
                    };
                    addDiscountCode({ variables: { ...variables, ...typeAttribute } });
                    if (i % 10 === 0) {
                      await new Promise((r) => setTimeout(r, 1000));
                    }
                    setPercentage(Math.round((i / data.numberOfVouchers) * 100));
                  }
                  voucherGroupCreated();
                }
              };

              return (
                <>
                  <GridItem columns="12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Box
                        preHeading="Voucher group"
                        heading="Add new voucher group"
                        showGoBackButton
                        goBackOnClick={() => history.push(`/admin/vouchers`)}>
                        {voucherGroupLoading && <ProgressLoader text={percentage} />}

                        <ActionButtons inBox footerOnMobile>
                          <ActionButton type="submit">
                            <i className="fal fa-fw fa-check" />
                            Save
                          </ActionButton>
                        </ActionButtons>
                        <RuleAttributes>
                          <Tooltip />
                          <Attribute>
                            <Label>Percentage / Fixed amount: </Label>
                            <Value>
                              <FixedToggle
                                active={discountFixed}
                                handleToggle={() => setDiscountFixed(!discountFixed)}
                              />
                            </Value>
                          </Attribute>
                          {!discountFixed && (
                            <Attribute>
                              <Label>Combine with item discounts: </Label>
                              <Value>
                                <Toggle
                                  data-tip="If this option is enabled, this discount code will stack with discounts on individual items"
                                  active={combine}
                                  handleToggle={() => setCombine(!combine)}
                                />
                              </Value>
                            </Attribute>
                          )}
                          {getEditFreeShipping() && (
                            <Attribute>
                              <Label>Free Shipping: </Label>
                              <Value>
                                <Toggle
                                  active={freeShipping}
                                  data-tip="If this option is enabled, free shipping will be applied on all shipping methods"
                                  handleToggle={() => setFreeShipping(!freeShipping)}
                                />
                              </Value>
                            </Attribute>
                          )}
                        </RuleAttributes>
                        <ButtonContainer>
                          <TypeButton
                            currentType={!filter}
                            type="button"
                            onClick={() => setFilter(false)}>
                            Product
                          </TypeButton>
                          <TypeButton
                            currentType={filter}
                            type="button"
                            onClick={() => setFilter(true)}>
                            Filter
                          </TypeButton>
                        </ButtonContainer>
                        <GridContainer collapse padding="3rem 0 0">
                          <GridItem
                            mobilePadding="3rem 0 0"
                            desktopPadding="0 1.5rem 0 0"
                            columns="12">
                            <Controller
                              name="name"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  label="Group name *"
                                  type="text"
                                  errors={errors}
                                  rules={{
                                    required: "This is a required field",
                                  }}
                                />
                              )}
                              control={control}
                              rules={{
                                required: "This is a required field",
                                validate: (name) =>
                                  findVoucherGroup({
                                    variables: {
                                      query: JSON.stringify(findVoucherGroupQuery(name).toJSON()),
                                    },
                                    fetchPolicy: "no-cache",
                                  }).then(
                                    (result) =>
                                      result.data.searchDiscounts.discounts.length === 0 ||
                                      "Group already exists"
                                  ),
                              }}
                            />
                          </GridItem>
                          <GridItem
                            mobilePadding="3rem 0 0"
                            desktopPadding="0 1.5rem 0 0"
                            columns="6">
                            <Controller
                              name="prefix"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  label="Prefix"
                                  placeholder="Optional prefix on generated codes"
                                  errors={errors}
                                  data-tip="Prefix on generated codes, ex: prefixOC6IHZMZKH (Optional)"
                                />
                              )}
                              rules={{
                                pattern: {
                                  value: /^[A-Za-z0-9_.]+$/,
                                  message: "Only characters Aa-Zz and 0-9 are allowed",
                                },
                              }}
                              control={control}
                            />

                            <StoreSelect
                              label="Select stores"
                              selectedStores={selectedStores}
                              setSelectedStores={updateSelectedStores}
                              showContinents
                            />

                            {storesMissingLabel && <ErrorLabel>No stores selected</ErrorLabel>}
                            <SelectDates>
                              <Controller
                                name="startDate"
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    type="date"
                                    label="Valid from *"
                                    errors={errors}
                                  />
                                )}
                                control={control}
                                rules={{
                                  required: "This is a required field",
                                  validate: (value) => moment(value, "YYYY-MM-DD", true).isValid(),
                                }}
                              />
                              <Controller
                                name="endDate"
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    type="date"
                                    label="Valid to *"
                                    errors={errors}
                                  />
                                )}
                                control={control}
                                rules={{
                                  required: "This is a required field",
                                  validate: (value) => moment(value, "YYYY-MM-DD", true).isValid(),
                                }}
                              />
                            </SelectDates>
                          </GridItem>
                          <GridItem mobilePadding="0" desktopPadding="0 0 0 1.5rem" columns="6">
                            <Controller
                              className="usageLimit"
                              name="usageLimit"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="number"
                                  label="Usage limit *"
                                  placeholder="Number of times code can be used"
                                  errors={errors}
                                />
                              )}
                              control={control}
                              rules={{
                                required: "This is a required field",
                                min: {
                                  value: 0,
                                  message: "Needs to be a number",
                                },
                              }}
                            />
                            <Controller
                              className="numberOfVouchers"
                              name="numberOfVouchers"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="number"
                                  label="Number of vouchers *"
                                  errors={errors}
                                />
                              )}
                              control={control}
                              rules={{
                                required: "This is a required field",
                                min: {
                                  value: 0,
                                  message: "Needs to be a number",
                                },
                                max: {
                                  value: 5000,
                                  message: "Max 5000 vouchers per group",
                                },
                              }}
                            />
                            {!discountFixed && (
                              <Controller
                                name="discountInPercentage"
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    type="number"
                                    placeholder={`0 - 100`}
                                    label="Discount in percentage *"
                                    icon="badge-percent"
                                    errors={errors}
                                  />
                                )}
                                control={control}
                                rules={{
                                  required: "This is a required field",
                                  min: {
                                    value: 0,
                                    message: `Needs to be a number between 0-100`,
                                  },
                                  max: {
                                    value: 100,
                                    message: `Needs to be a number between 0-100`,
                                  },
                                }}
                              />
                            )}
                          </GridItem>
                          {filter && (
                            <GridItem padding="0 1rem 0 0" columns="6">
                              <Controller
                                name="minItems"
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    type="number"
                                    label="Min items"
                                    icon="cart-minus"
                                    errors={errors}
                                    data-tip="The minimum number of matching items in the cart required for the discount to apply"
                                  />
                                )}
                                control={control}
                                rules={{
                                  required: "This is a required field",
                                  min: {
                                    value: 1,
                                    message: `Needs to be a positive number`,
                                  },
                                }}
                              />
                            </GridItem>
                          )}
                          {discountFixed && (
                            <DiscountCodeCurrencies
                              currencies={availableCurrencies}
                              control={control}
                              errors={errors}
                            />
                          )}
                        </GridContainer>
                        {filter && (
                          <FilterRules
                            control={control}
                            filterRules={filterRules}
                            setFilterRules={setFilterRules}
                          />
                        )}
                      </Box>
                      <SaveBar />
                    </form>
                  </GridItem>
                  {filter && <FilterProducts filterRules={filterRules} />}
                </>
              );
            }}
          </Mutation>
        </GridContainer>
      </PageContainer>
    </>
  );
};

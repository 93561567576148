import React from "react";
import { useQuery } from "@apollo/client";
import ORDER_AGGREGATE from "graphql/Order/OrderAggregate";
import Box from "components/Dashboard/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import moment from "moment-timezone";
import Map from "components/Map/WorldMap";
import CountryList from "components/Lists/CountryList";
import esb from "elastic-builder";

const toData = (stats, timeRange, stores, format) => {
  const storeBuckets = stats.stores.buckets;
  const mappedStats = [];
  for (const storeBucket of storeBuckets) {
    mappedStats.push({
      store: storeBucket.key,
      orderCount: storeBucket.created.buckets.map((d) => {
        const date = moment(d.key_as_string).format(format);
        if (timeRange.includes(date)) {
          return {
            key: date,
            value: d.doc_count,
          };
        } else {
          return {
            key: date,
            value: 0,
          };
        }
      }),
    });
  }

  stores &&
    stores.forEach((store) => {
      if (!mappedStats.find((s) => s.store === store.countryCode))
        mappedStats.push({
          store: store.countryCode,
          orderCount: timeRange.map((r) => ({ key: r, value: 0 })),
        });
    });
  return mappedStats;
};

const getTimeRange = (startDateTime, endDateTime, type, format) => {
  const from = moment(startDateTime);
  const to = moment(endDateTime);
  return [...Array(to.diff(from, type)).keys()].map((i) =>
    moment(startDateTime).add(i, type).format(format)
  );
};

const getNumberOfOrdersQuery = (startDateTime, endDateTime, selectedStores, format, interval) =>
  new esb.requestBodySearch()
    .query(
      esb
        .boolQuery()
        .must([
          esb.matchQuery("statusLog.status", "success"),
          esb.termsQuery("store.keyword", selectedStores),
          esb
            .rangeQuery("created")
            .gte(startDateTime.format(format))
            .lte(endDateTime.format(format))
            .format(format.replace("YYYY", "yyyy").replace("DD", "dd"))
            .timeZone(moment.tz.guess()),
        ])
    )
    .agg(
      esb
        .termsAggregation("stores", "store.keyword")
        .size(300)
        .agg(esb.dateHistogramAggregation("created", "created", interval))
    );

export default ({ stores, selectedStores, period }) => {
  if (!selectedStores) return <Loader />;
  const obj = Object.assign(
    {},
    ...["current", "prev"].map((key) => {
      const timeRange = getTimeRange(
        period[key].startDate,
        period[key].endDate,
        period.type,
        period.format
      );

      const { loading, error, data } = useQuery(ORDER_AGGREGATE, {
        variables: {
          query: JSON.stringify(
            getNumberOfOrdersQuery(
              period[key].startDate,
              period[key].endDate,
              selectedStores,
              period.format,
              period.interval
            ).toJSON()
          ),
        },
      });

      if (loading) return { [key]: { loading } };
      if (error) return { [key]: { error } };
      if (data) {
        const diagramData = toData(
          JSON.parse(data.orderAggregates.aggregations),
          timeRange,
          stores,
          period.format
        );

        let mapData;
        mapData = Object.assign(
          {},
          ...diagramData.map((d) => ({
            [d.store]: d.orderCount.reduce((a, b) => a + b.value, 0),
          }))
        );

        const diagramDataForStore = diagramData
          .filter((d) => selectedStores.includes(d.store))
          .map((d) => d.orderCount)
          .flat()
          .reduce((acc, curr) => {
            const item = acc.find((i) => i.key === curr.key);
            item ? (item.value += curr.value) : acc.push(curr);
            return acc;
          }, []);

        const diagramDataAmount = diagramDataForStore.map((d) => d.value);
        return {
          [key]: {
            range: timeRange,
            data: mapData ?? [],
            amounts: diagramDataAmount,
            sum: diagramDataAmount.reduce((a, b) => a + b, 0),
          },
        };
      }
      return null;
    })
  );

  if (obj.prev.loading || obj.current.loading) return <Loader />;
  if (obj.prev.error || obj.current.error)
    return <ErrorMessage>An error occurred when loading data, please contact support</ErrorMessage>;
  if (!obj.current || !obj.prev) return null;

  return (
    <>
      <GridItem columns="12" padding="0">
        <Box
          preHeading="Total orders"
          heading={`# ${obj.current.sum}`}
          headingIcon="shopping-cart"
          currentPeriodSum={obj.current.sum}
          prevPeriodSum={obj.prev.sum}
          primaryLabel={period.current.label}
          secondaryLabel={period.prev.label}
          labelPos="topRight"
          footer={period.label}>
          <GridContainer padding="0" collapse>
            <GridItem columns="7" padding="0">
              <Map mapData={obj.current.data} />
            </GridItem>
            <GridItem columns="5" padding="0">
              <CountryList obj={obj} />
            </GridItem>
          </GridContainer>
        </Box>
      </GridItem>
    </>
  );
};

import React from "react";
import PageContainer from "components/Page/PageContainer";
import GridContainer from "components/Grid/GridContainer";
import Box from "components/Content/Box";
import Moment from "react-moment";
import Sidebar from "components/Store/Sidebar";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import { useForm, Controller } from "react-hook-form";
import ADD_OR_UPDATE_STORE from "graphql/Store/AddOrUpdateStore";
import { Mutation } from "@apollo/client/react/components";
import { useNotification } from "context/NotificationContext";
import GridItem from "components/Grid/GridItem";
import getFullCountryName from "helpers/getFullCountryName";
import FlagIcon from "components/Ui/FlagIcon";
import Loader from "components/Ui/Loader";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import DeleteStore from "components/Store/DeleteStore";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import InputNew from "components/Ui/InputNew";
import styled from "styled-components";
import ISO6391 from "iso-639-1";
import InventoriesPriorityTable from "components/Store/Inventory/InventoriesPriorityTable";
import getStockVersion from "helpers/getStockVersion";

const HeadingFlag = styled(FlagIcon)`
  margin-left: 1rem;
`;

const InventoryBox = styled(Box)`
  margin-top: 2rem;
`;

const Form = styled.form`
  width: 100%;
`;

const Store = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setNotification } = useNotification();
  const { countryCode, languageCode, created, lastUpdated, tax, currencyUnit } =
    props.location.state;
  const inventories = props.location.state?.inventories;

  return (
    <>
      <Breadcrumbs slugs={[["admin/stores", "Stores"], [`admin/store/`]]} />
      <Header heading="Stores" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Mutation
            mutation={ADD_OR_UPDATE_STORE}
            onCompleted={() => {
              setNotification({
                status: "success",
                message: `Store successfully updated`,
              });
            }}
            onError={() => {
              setNotification({
                status: "error",
                message: "An error occurred adding the store, please contact support",
              });
            }}>
            {(updateStore, { loading: addStoreLoading }) => {
              const onSubmit = (data) => {
                updateStore({
                  variables: {
                    countryCode: countryCode,
                    languageCode: languageCode,
                    tax: data.tax,
                    currencyUnit: data.currencyUnit.toUpperCase(),
                  },
                }).catch((e) => {
                  console.log(e);
                });
              };
              return (
                <GridItem columns="12">
                  <Box
                    preHeading="Store"
                    heading={
                      <>
                        {getFullCountryName(countryCode)}
                        <HeadingFlag countryCode={countryCode} />
                      </>
                    }
                    subHeading={countryCode}
                    showGoBackButton
                    goBackOnClick={() => props.history.push("/admin/stores")}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      {addStoreLoading && <Loader />}
                      <ActionButtons inBox footerOnMobile>
                        <DeleteStore
                          countryCode={countryCode}
                          languageCode={languageCode}
                          history={props.history}
                        />
                        <ActionButton type="submit">
                          <i className="fal fa-fw fa-check" />
                          Save
                        </ActionButton>
                      </ActionButtons>
                      <Attributes>
                        <Attribute>
                          <Label>Created:</Label>
                          <Value>
                            <Moment format="YYYY-MM-DD HH:mm">{created}</Moment>
                          </Value>
                        </Attribute>
                        <Attribute>
                          <Label>Last updated:</Label>
                          <Value>
                            <Moment format="YYYY-MM-DD HH:mm">{lastUpdated}</Moment>
                          </Value>
                        </Attribute>
                        <Attribute>
                          <Label>Language:</Label>
                          <Value>{ISO6391.getName(languageCode) || languageCode}</Value>
                        </Attribute>
                        <Attribute>
                          <Label>Language code:</Label>
                          <Value>{languageCode}</Value>
                        </Attribute>
                      </Attributes>
                      <GridContainer padding="2rem 0">
                        <GridItem
                          mobilePadding="2rem 0 0"
                          desktopPadding="0 1.5rem 0 0"
                          columns="6">
                          <Controller
                            control={control}
                            name="tax"
                            defaultValue={tax}
                            rules={{
                              required: "This is a required field",
                              min: {
                                value: 0,
                                message: `Needs to be a number between 0-40`,
                              },
                              max: {
                                value: 40,
                                message: `Needs to be a number between 0-40`,
                              },
                            }}
                            render={({ field: { onChange, onBlur } }) => (
                              <InputNew
                                name="tax"
                                label="Tax"
                                icon="fal fa-percentage"
                                type="number"
                                onBlur={onBlur}
                                onChange={onChange}
                                defaultValue={tax}
                                errors={errors}
                              />
                            )}
                          />
                        </GridItem>
                        <GridItem mobilePadding="2rem 0" desktopPadding="0 1.5rem 0 0" columns="6">
                          <Controller
                            control={control}
                            name="currencyUnit"
                            defaultValue={currencyUnit}
                            rules={{
                              required: "This is a required field",
                              maxLength: {
                                value: 3,
                                message: "Maximum characters is 3",
                              },
                              minLength: {
                                value: 3,
                                message: "Minimum characters is 3",
                              },
                              pattern: {
                                value: /^[a-zA-Z]+$/,
                                message: "Only characters a-z are allowed",
                              },
                            }}
                            render={({ field: { onChange, onBlur } }) => (
                              <InputNew
                                name="currencyUnit"
                                label="Currency unit"
                                icon="fal fa-coins"
                                onBlur={onBlur}
                                onChange={onChange}
                                defaultValue={currencyUnit}
                                errors={errors}
                              />
                            )}
                          />
                        </GridItem>
                      </GridContainer>
                    </Form>
                  </Box>
                  {getStockVersion() !== "v1" && (
                    <InventoryBox
                      heading={
                        <>
                          Inventories
                          <HeadingFlag countryCode={countryCode} />
                        </>
                      }
                      subHeading="Priority">
                      <InventoriesPriorityTable
                        inventoriesTotalHits={inventories?.length}
                        inventories={inventories}
                        history={props.history}
                      />
                    </InventoryBox>
                  )}
                </GridItem>
              );
            }}
          </Mutation>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default Store;

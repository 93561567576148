import React from "react";
import PageContainer from "components/Page/PageContainer";
import Box from "components/Content/Box";
import Moment from "react-moment";
import Sidebar from "components/Store/Sidebar";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridItem from "components/Grid/GridItem";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import styled from "styled-components";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Name = styled.span`
  i {
    margin-right: 1rem;
  }
`;

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  top: 0;

  ${MEDIA_MIN_LARGE} {
    padding: ${(p) => p.padding || "1.5rem"};
    position: absolute;
    top: 15rem;
  }
`;

const Inventory = (props) => {
  const { id, name, address, created, updated } = props.location.state ?? {};

  return (
    <>
      <Breadcrumbs slugs={[["admin/inventory", "Inventories"], [`admin/inventory/`]]} />
      <Header heading="Inventories" />
      <PageContainer>
        <Sidebar />
        <Box
          preHeading="Inventory"
          heading={
            <Name>
              <i className="fa-duotone fa-warehouse-full"></i>
              {name}
            </Name>
          }
          subHeading={id}
          showGoBackButton
          goBackOnClick={() => props.history.push("/admin/inventories")}>
          <TableWrapper>
            <GridItem mobilePadding="2rem 0 0" desktopPadding="0 1.5rem 0 0" columns="6">
              <Attributes>
                <Attribute>
                  <Label>Id:</Label>
                  <Value>{id}</Value>
                </Attribute>
                <Attribute>
                  <Label>Name:</Label>
                  <Value>{name}</Value>
                </Attribute>
                <Attribute>
                  <Label>Created:</Label>
                  <Value>
                    <Moment format="YYYY-MM-DD HH:mm">{created}</Moment>
                  </Value>
                </Attribute>
                <Attribute>
                  <Label>Last updated:</Label>
                  <Value>
                    <Moment format="YYYY-MM-DD HH:mm">{updated}</Moment>
                  </Value>
                </Attribute>
              </Attributes>
            </GridItem>
            {address && (
              <GridItem mobilePadding="2rem 0 0" desktopPadding="0 1.5rem 0 0" columns="6">
                <Attributes>
                  <Attribute>
                    <Label>Country:</Label>
                    <Value>{address.country}</Value>
                  </Attribute>
                  <Attribute>
                    <Label>City:</Label>
                    <Value>{address.city}</Value>
                  </Attribute>
                  <Attribute>
                    <Label>Street address:</Label>
                    <Value>{address.streetAddress}</Value>
                  </Attribute>
                  <Attribute>
                    <Label>Postal code:</Label>
                    <Value>{address.postalCode}</Value>
                  </Attribute>
                  <Attribute>
                    <Label>Region:</Label>
                    <Value>{address.region}</Value>
                  </Attribute>
                </Attributes>
              </GridItem>
            )}
          </TableWrapper>
        </Box>
      </PageContainer>
    </>
  );
};

export default Inventory;
